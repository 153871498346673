import styled, { css } from 'styled-components';
import ButtonDefault from 'ui/Button/Default';
import ButtonClean from 'ui/Button/Clean';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import LinkButton from 'ui/Button/LinkButtonDefault';

export const Container = styled.div``;

export const SectionStep = styled.div`
  ${({ theme }) => css`
    margin-top: 10px;
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      background-color: ${theme.colors.primary};
      font-weight: 700;
      width: 100px;
      padding: 8px;
      border-radius: 30px;
      margin-bottom: 20px;
    }
    @media (min-width: 600px) {
    }
  `};
`;

export const SectionInput = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    button {
      margin-top: 20px;
      width: 100%;
    }
    @media (min-width: 600px) {
      flex-direction: row;
      button {
        margin-top: 20px;
        width: 220px;
      }
    }
  `};
`;

export const WrapperInput = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    margin-right: 15px;
    label {
      font-weight: bolder;
    }
    input {
      margin-top: 10px;
      width: 100%;
    }
    @media (min-width: 600px) {
      input {
        margin-top: 10px;
        width: 250px;
      }
    }
  `};
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    margin-bottom: 15px;
    font-size: 19px;
    line-height: 27px;
    font-weight: 800;
    color: ${theme.colors.primary};
    text-transform: capitalize;
    font-family: ${theme.font.family.montserrat};
    text-align: center;
    @media (min-width: 600px) {
      text-align: left;
      margin-bottom: 20px;
      font-size: 49px;
      line-height: 47px;
      margin-top: 15px;
    }
  `};
`;

export const Subtitle = styled.h3`
  ${({ theme }) => css`
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 17px;
    font-weight: 600;
    font-family: ${theme.font.family.visby};
    @media (min-width: 600px) {
      margin-bottom: 30px;
      font-size: 23px;
      line-height: 30px;
    }
  `};
`;

export const Button = styled(ButtonDefault)`
  background-color: ${({ isSemiActive }) =>
    isSemiActive ? 'rgba(94, 214, 213, 0.5)' : null};
  border: 3px solid white;
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-out;
  border-radius: 40px;
  padding: 14px;
  width: 100%;
  @media (min-width: 600px) {
    width: 80%;
    height: 55px;
    margin-bottom: 0;
  }
`;

export const SectionLink = styled.div`
  ${({ isVisible }) => css`
    display: ${isVisible ? 'flex' : 'none'};
    flex-direction: column;
    margin-bottom: 30px;
    p {
      margin-bottom: 30px;
      font-size: 16px;
      word-wrap: break-word;
    }
    span {
      font-style: italic;
      font-size: 14px;
    }
    @media (min-width: 600px) {
    }
  `};
`;

export const WrapperTestButton = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    @media (min-width: 600px) {
      flex-direction: row;
    }
  `};
`;

export const TestLinkButton = styled(LinkButton)`
  ${() => css`
    width: 100%;
    margin-right: 15px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    @media (min-width: 600px) {
      width: 190px;
      margin-bottom: 0;
    }
  `};
`;

export const IconOpenLink = styled(OpenInNewIcon)`
  ${() => css`
    && {
      margin-left: 5px;
      fill: white;
      font-size: 16px;
      @media (min-width: 600px) {
      }
    }
  `}
`;

export const SectionErrorLink = styled.div`
  ${({ isVisible }) => css`
    display: ${isVisible ? 'block' : 'none'};
    @media (min-width: 600px) {
    }
  `};
`;

export const SectionBottom = styled.div`
  ${() => css`
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    @media (min-width: 600px) {
    }
  `};
`;

export const ButtonSave = styled(ButtonDefault)`
  ${() => css`
    width: 100%;
    margin-right: 15px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (min-width: 600px) {
      width: 190px;
      margin-bottom: 0;
    }
  `};
`;
export const ButtonCancel = styled(ButtonClean)`
  ${() => css`
    width: 100%;
    margin-right: 15px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (min-width: 600px) {
      width: 190px;
      margin-bottom: 0;
    }
  `};
`;
