import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import styled from 'styled-components';

const onReady = videoId => {};

const Player = ({ className, url }) => (
  <ReactPlayer
    url={url}
    className={className}
    width="100%"
    height="100%"
    onReady={onReady('test')}
  />
);

const AbsolutelyPositionedPlayer = styled(Player)`
  position: absolute;
  top: 0;
  left: 0;
`;

const RelativePositionWrapper = styled.div`
  position: relative;
  padding-top: 56.25%;
`;

const ResponsiveStyledPlayer = props => (
  <RelativePositionWrapper>
    <AbsolutelyPositionedPlayer url={props.url} />
  </RelativePositionWrapper>
);

ResponsiveStyledPlayer.propTypes = {
  className: PropTypes.string,
  url: PropTypes.string,
};

Player.propTypes = {
  className: PropTypes.string,
  url: PropTypes.string,
};

export default ResponsiveStyledPlayer;
