import React from 'react';
import styled, { css } from 'styled-components';

const ContainerErrorMessageConfirmAccount = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffefef;
    border: 1px solid rgba(255, 0, 0, 0.3);
    text-align: center;
    padding: 20px 10;
    border-radius: 8px;
    margin-bottom: 40px;
    h3 {
      display: flex;
      align-items: center;
      color: red;
      font-weight: bolder;
      font-size: 26px;
      margin: 20px 0 10px;
    }
    p {
      padding: 30px;
      line-height: 22px;
    }
    span {
      font-weight: bolder;
      margin-bottom: 20px;
    }
    @media (min-width: 600px) {
    }
  `};
`;

const ErrorMessageConfirmAccount = () => (
  <ContainerErrorMessageConfirmAccount>
    <p>
      If the link we created did not work, make sure you entered your doTERRA ID
      correctly. If you made a mistake, please correct your doTERRA ID and try
      the process again. If you need assistance confirming your doTERRA ID or
      making sure you are currently a Wellness Advocate, please contact doTERRA
      Support: 1-800-411-8151
    </p>
  </ContainerErrorMessageConfirmAccount>
);

export default ErrorMessageConfirmAccount;
