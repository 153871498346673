import React, { useState, useEffect } from 'react';
import { PageContent } from 'ui/layouts';
import GoBackToTheFlowPage from 'ui/GoBackToTheFlowPage';
import WelcomeHeader from 'ui/WelcomeHeader';
import {
  SectionHeader,
  WrapperTitle,
  Title,
  Subtitle,
  SectionBenefits,
  Benefit,
  SectionDisclaimer,
  IconCheck,
  SectionDisclaimerTwo,
  SectionBoxInfo,
  BoxInfo,
  HighlightBox,
  TextMiddle,
} from 'pages/BecomeVIP/styles';
import SignUpFormModal from 'pages/BecomeVIP/ModalSignUpForm';
// import StripeButton from './StripeButton';
import SectionPrice from 'pages/BecomeVIP/SectionPrice';
import ExcitedImg from 'assets/img/becomeVip/excited.jpg';
import HeaderImg from 'assets/img/becomeVip/become-vip.png';

const BecomeVIP = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [interval, setInterval] = useState('monthly');

  const handleClosedMoldal = () => setIsModalOpen(false);

  const handleOpenMoldal = newInterval => {
    setInterval(newInterval);
    setIsModalOpen(true);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <WelcomeHeader />
      <PageContent>
        <GoBackToTheFlowPage />

        <SectionHeader>
          <img src={HeaderImg} alt="header-image" width="150px" />
          <WrapperTitle>
            <Title>Become a VIP Member</Title>
            <Subtitle>Try it FREE for 14 days!</Subtitle>
          </WrapperTitle>
        </SectionHeader>
        <HighlightBox>
          Act fast to be one of the first 500 VIP Members and receive
          *early-bird pricing!
        </HighlightBox>
        <SectionPrice handleOpenMoldal={handleOpenMoldal} />
        <TextMiddle>
          As a VIP Member, you can start building your business online TODAY.
          Activate your membership and you'll gain immediate access to...
        </TextMiddle>
        <SectionBenefits>
          <div>
            <Benefit>
              <IconCheck />
              <div>The Power and support of the Drippl platform</div>
              <p>
                No need to design your own website, marketing videos, education
                content, etc. We’ve done it for you!
              </p>
            </Benefit>
            <Benefit>
              <IconCheck />
              <div>Your own unique referral link for sharing</div>
              <p>
                Refer others to the dōTERRA business opportunity and the Drippl
                platform and let our professionally designed marketing funnel
                help invite, educate, and enroll your contacts onto your team!
              </p>
            </Benefit>
            <Benefit>
              <IconCheck />
              <div>A complete, done-for-you nurture sequence</div>
              <p>
                Your contacts can explore the opportunity at their own pace, but
                our follow-up email nurture sequence keeps them engaged,
                learning, and evaluating the opportunity.
              </p>
            </Benefit>
          </div>
          <div>
            <Benefit>
              <IconCheck />
              <div>A system that sells enrollment kits for you</div>
              <p>
                Let Drippl introduce your contacts to the enrollment kits and
                invite them to get started with doTERRA!
              </p>
            </Benefit>
            <Benefit>
              <IconCheck />
              <div>Social media coaching</div>
              <p>
                Learn the latest tips and tricks for growing a following and
                attracting new contacts!
              </p>
            </Benefit>
            <Benefit>
              <IconCheck />
              <div>VIP Member-only webinars and trainings</div>
              <p>
                Business-building best practices, motivation, inspiration, and
                much more – we pull back the curtain on all of it and equip you
                with the know-how you need to crush it!
              </p>
            </Benefit>
          </div>
        </SectionBenefits>
        <SectionDisclaimer src={ExcitedImg}>
          <div>
            <div />
          </div>
          <div>
            <h2>
              Oh, yeah… and you get all of this at our early-adopter, discounted
              rate of only $4.95/month!
            </h2>
            <span>
              (NOTE: This discount is available for a super limited time before
              we raise the price forever! You can cancel or pause your billing
              at any time)
            </span>
          </div>
        </SectionDisclaimer>
        <SectionBoxInfo>
          <BoxInfo>
            <span>X</span>
            <div>NO need to hire a marketing team</div>
          </BoxInfo>
          <BoxInfo>
            <span>X</span>
            <div>NO need to build your own website</div>
          </BoxInfo>
          <BoxInfo>
            <span>X</span>
            <div>
              NO need to pay upwards of $100/month for a funnel that may or may
              not work ;)
            </div>
          </BoxInfo>
        </SectionBoxInfo>
        <SectionDisclaimerTwo>
          <div>
            And get excited! To accommodate worldwide growth and international
            scalability, we are working on launching Drippl in various languages
            while enhancing functionality, features, and support services for
            the best, most effective universal experience.
          </div>
          <div>Become a Drippl VIP and keep your low rate forever!</div>
        </SectionDisclaimerTwo>
        <SectionPrice handleOpenMoldal={handleOpenMoldal} />
        {/* <div>
          <StripeButton interval="monthly">Stripe Test Monthly</StripeButton>
          <StripeButton interval="yearly">Stripe Test Yearly</StripeButton>
        </div> */}
        <SignUpFormModal
          isOpen={isModalOpen}
          interval={interval}
          handleClose={handleClosedMoldal}
        />
      </PageContent>
    </>
  );
};

export default BecomeVIP;
