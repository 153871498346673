import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'ui/Modal';
import Input from 'ui/InputText';
import {
  Container,
  SectionHeader,
  SectionBody,
  WrapperInput,
  ButtonSave,
  ButtonHelp,
  SectionClose,
  CloseModal,
} from 'pages/TheFlow/Steps/ValidateDoTerraIDModal/styles';
import useReactRouter from 'use-react-router';
import { useAuth0 } from 'context/auth';

const SCOTT_DOTERRA_ID = '6514085';

const PrepareToShareModal = ({ isOpen, handleClose }) => {
  const { http } = useAuth0();
  const { history } = useReactRouter();
  const [doterraId, setDoterraId] = useState('');
  const [isShowGetHelped, setIsShowGetHelped] = useState(false);

  const handleInput = event => setDoterraId(event.target.value);

  const onSubmit = () => {
    history.push({
      pathname: '/wholesale-account',
      state: {
        memberId: doterraId,
      },
    });
    handleClose();
  };

  const handleEmailClick = () => {
    window.open(`mailto:support@drippl.com`, '_blank');
  };

  const handleGetWellnessAdvocate = () => {
    setIsShowGetHelped(true);
  };

  const handleCloseHelped = () => {
    setIsShowGetHelped(false);
    //send email
    http.post(`/send-mail`,{
      input:{
        mailType:'needBusinessLeader'
      }
    })

    history.push({
      pathname: '/wholesale-account',
      state: {
        memberId: SCOTT_DOTERRA_ID,
      },
    });
    handleClose();
  };

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} hideButtons>
      <Container isVisible={!isShowGetHelped}>
        <CloseModal onClick={handleClose}>
          <span>X</span>
        </CloseModal>
        <SectionHeader>
          <h2>Identify Your dōTERRA Wellness Advocate</h2>
        </SectionHeader>
        <SectionBody>
          <p>
            We are excited that you are ready to look at dōTERRA’s starter kits.
            Please select from one of the following options to move forward:
          </p>
          <br />
        </SectionBody>
        <SectionBody>
          <p>
            <strong>Option One:</strong> Provide the dōTERRA ID for someone
            currently selling dōTERRA products if you have it,{' '}
            <strong>OR</strong> simply click the button below if you would like
            to be taken to the next page to see the kits. (Please choose this
            option if you know somebody who is currently working to build a
            dōTERRA business.)
          </p>
          <WrapperInput>
            <Input
              name="doterraid"
              placeholder="DoTerra ID"
              value={doterraId}
              onChange={handleInput}
            />
            <ButtonSave onClick={onSubmit}>Show me the kits</ButtonSave>
          </WrapperInput>
          <br />
        </SectionBody>
        <SectionBody>
          <p>
            <strong>Option Two:</strong> Select this option if you do not
            currently know somebody who is selling dōTERRA products, or if you
            would like assistance in finding a great potential business partner
            and mentor.
          </p>
          <ButtonHelp onClick={handleGetWellnessAdvocate}>
            Help me find a business partner and mentor
          </ButtonHelp>
        </SectionBody>
      </Container>
      <Container isVisible={isShowGetHelped}>
        <SectionHeader>
          <h2>Thank you! Please check your email for next steps.</h2>
        </SectionHeader>
        <SectionClose>
          If you need more immediate assistance, please contact:
          <span id="email-click" onClick={handleEmailClick}>
            support@drippl.com
          </span>
        </SectionClose>
        <SectionBody>
          <ButtonHelp onClick={handleCloseHelped}>Continue</ButtonHelp>
        </SectionBody>
      </Container>
    </Modal>
  );
};

export default PrepareToShareModal;

PrepareToShareModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
};
