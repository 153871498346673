import gql from 'graphql-tag';

export const SELF_UPDATE_PROFILE = gql`
  mutation selfUpdateProfile(
    $firstName: String
    $lastName: String
    $phone: String
  ) {
    selfUpdateProfile(
      input: { firstName: $firstName, lastName: $lastName, phone: $phone }
    ) {
      id
      firstName
      lastName
    }
  }
`;
