import { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_THE_FLOW_INFO } from 'graphql/queries/getTheFlowInfo';
import { UPDATE_POSITION_STATUS } from 'graphql/mutations/updatePositionStatus';
import { UPDATE_OWE_STATUS } from 'graphql/mutations/updateMemberStatus';
import { UPDATE_MEMBER_STATUSES } from 'graphql/mutations/updateStatuses';
import { PREMEMBER_PAGE } from 'pages/TheFlow/data/prememberPage';
import { MEMBER_PAGE } from 'pages/TheFlow/data/memberPage';
import { VIP_PAGE } from 'pages/TheFlow/data/vipPage';
import { SETUP_PAGE } from 'pages/TheFlow/data/setupPage';

import get from 'lodash/get';

const videoInfo = {
  id: '',
  name: '',
  coverImage: {
    url: '',
  },
};

const PAGES = {
  PREMEMBER: PREMEMBER_PAGE,
  MEMBER: MEMBER_PAGE,
  VIP: VIP_PAGE,
  SETUP: SETUP_PAGE,
};

const defaultStatusBar = {
  EXPLORE: {},
  TRY: {},
  PREPARE: {},
  AUTOMATE: {},
};

const defaultVideos = {
  explore: videoInfo,
  learn: videoInfo,
  build: videoInfo,
};

const useTheFlow = () => {
  const { loading, error, data } = useQuery(GET_THE_FLOW_INFO);
  const [updateUser] = useMutation(UPDATE_POSITION_STATUS);
  const [updateOWEStatus] = useMutation(UPDATE_OWE_STATUS);
  const [updateMemberStatuses] = useMutation(UPDATE_MEMBER_STATUSES);

  const [pageContent, setPageContent] = useState(PREMEMBER_PAGE.EMPTY);
  const [statusBar, setStatusBar] = useState(defaultStatusBar);
  const [videosInfo, setVideosInfo] = useState(defaultVideos);

  const handleUpdatePositionStatus = async status => {
    await updateUser({
      variables: { status },
      refetchQueries: () => [
        {
          query: GET_THE_FLOW_INFO,
        },
      ],
    });
  };

  const handleUpdateMemberStatuses = async ({ positionStatus, oweStatus }) => {
    await updateMemberStatuses({
      variables: { positionStatus, oweStatus },
      refetchQueries: () => [
        {
          query: GET_THE_FLOW_INFO,
        },
      ],
    });
  };

  const handleUpdateMemberStatus = async status => {
    await updateOWEStatus({
      variables: { status },
      refetchQueries: () => [
        {
          query: GET_THE_FLOW_INFO,
        },
      ],
    });
  };

  useEffect(() => {
    if (data && data.self) {
      const { oweStatus, positionStatus } = data.self;
      const PAGE_CONTENT = get(
        PAGES,
        `${oweStatus}.${positionStatus}`,
        PREMEMBER_PAGE.EXPLORING
      );
      setPageContent(PAGE_CONTENT);
      const selectedStatusBar = get(
        PAGE_CONTENT,
        'header.stepStatus',
        defaultStatusBar
      );
      setStatusBar(selectedStatusBar);
    }
    if (data && data.labModules) {
      const labVideos = {
        explore: get(data.labModules, '[0]', {}),
        learn: get(data.labModules, '[1]', {}),
        build: get(data.labModules, '[2]', {}),
      };
      setVideosInfo(labVideos);
    }
  }, [data]);

  return {
    loading,
    error,
    data,
    handleUpdatePositionStatus,
    handleUpdateMemberStatus,
    handleUpdateMemberStatuses,
    pageContent,
    statusBar,
    videosInfo,
    oweStatus: get(data, 'self.oweStatus', 'SETUP'),
    referralId: get(data, 'self.referredBy.doterraAccount.memberId', ''),
  };
};

export default useTheFlow;
