import gql from 'graphql-tag';

export const GET_MEMBER_REFER = gql`
  query getMemberRefer {
    self {
      id
      referredBy {
        id
        fullName
        email
        profilePicture
      }
    }
  }
`;
