import React from 'react';
import {
  Container,
  WrapperImage,
  LogoImage,
  WrapperTitle,
  Title,
  Subtitle,
} from 'ui/WelcomeHeader/styles';

const WelcomeHeader = () => (
  <Container>
    <WrapperImage>
      <LogoImage src="https://uploads-ssl.webflow.com/5c804d7af674b581bbe853f3/5d9de1fbd4ab47137d5ea954_handlightning.png" />
    </WrapperImage>
    <WrapperTitle>
      <Title>Welcome to Drippl</Title>
      <Subtitle>Your doTERRA Business Automation Platform</Subtitle>
    </WrapperTitle>
  </Container>
);

export default WelcomeHeader;
