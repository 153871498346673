import { useLazyQuery } from '@apollo/react-hooks';
import { GET_MODULE } from 'graphql/queries/getModule';
import get from 'lodash/get';

const useModule = () => {
  const [fetchModule, { loading, error, data }] = useLazyQuery(GET_MODULE);

  return {
    loading,
    error,
    data: get(data, 'labModule'),
    fetchModule,
  };
};

export default useModule;
