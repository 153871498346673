import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 64px;
  @media (min-width: 600px) {
    padding-top: 0;
    padding-left: 240px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 20px;
  @media (min-width: 600px) {
    padding: 0 20px;
  }
`;

export const ContactBar = styled.div`
  height: 64px;
  background-color: white;
  @media (min-width: 600px) {
  }
`;
