import React, { useState, useEffect, useCallback } from 'react';
import {
  ModalStyled,
  Container,
  Image,
  Content,
  Button,
  Section,
  WrapperImage,
} from 'ui/ModalCookie/styles';
import img from 'assets/img/cookie.png';

const ModalCookie = () => {
  const [isOpen, setIsOpen] = useState(false);

  const setCookie = useCallback(() => {
    document.cookie = 'acceptedCookies=true; expires=31536000';
    handleClose();
  }, []);

  const readCookie = () => {
    const cookieValue = document.cookie.replace(
      /(?:(?:^|.*;\s*)acceptedCookies\s*=\s*([^;]*).*$)|^.*$/,
      '$1'
    );
    return Boolean(cookieValue);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (!readCookie()) {
      setIsOpen(true);
    }
  }, []);

  return (
    <ModalStyled open={isOpen} onClose={handleClose}>
      <Container>
        <Section>
          <WrapperImage>
            <Image src={img} />
          </WrapperImage>
          <Content>
            This site uses cookies to provide you with a great user experience
            and keep track of the person who might have referred you. By using
            Drippl, you accept our use of cookies.
          </Content>
          <Button onClick={setCookie}>ACCEPT</Button>
        </Section>
      </Container>
    </ModalStyled>
  );
};

export default ModalCookie;
