import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useClipboard from 'react-use-clipboard';
import Modal from 'ui/Modal';
import {
  Wrapper,
  Container,
  Content,
  ButtonClean,
  ButtonMain,
  TextLink,
  ContainerModal,
  Icon,
  ModalTitle,
} from 'pages/TheFlow/UserUtils/PersonalLink/styles';
import { Header } from 'pages/TheFlow/UserUtils/styles';
import LinkOutlinedIcon from '@material-ui/icons/LinkOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import useReactRouter from 'use-react-router';
import usePersonalLink from 'pages/TheFlow/UserUtils/data/usePersonalLink';
import get from 'lodash/get';

const NotificationModal = ({ isOpen, handleClose }) => (
  <Modal isOpen={isOpen} handleClose={handleClose} cancelLabel="Got it">
    <ContainerModal>
      <Icon />
      <ModalTitle>How Does Your Personal Referral Link Work?</ModalTitle>
      <p>
        Your personalized link makes it possible for Drippl to track any
        visitors you refer. When your referrals decide to learn more, we begin
        the process of educating them about doTERRA and invite them to try the products. YOUR referrals
        will see YOUR picture as we interact with them. You can share this link
        on social media posts, texts, emails, and any other creative way you can
        think to share it. When your referrals are ready to purchase products or
        start building their business, they will be taken to your doTERRA enrollment site
        where they will buy products and enroll as part of your team!
      </p>
    </ContainerModal>
  </Modal>
);

NotificationModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
};

const PersonalLink = () => {
  const { history } = useReactRouter();
  const { data } = usePersonalLink();
  const [isOpen, setIsOpen] = useState(false);
  const [referralUrl, setReferralUrl] = useState('');
  const [vipAndBuilder, setVpAndBuilder] = useState(false);
  const [isLinkCopied, setCopied] = useClipboard(referralUrl);
  const handleTogglePopUp = () => setIsOpen(prevState => !prevState);
  const handleGetVip = () => history.push('/become-vip');

  useEffect(() => {
    if (data) {
      const positionStatus = get(data, 'self.positionStatus', '');
      const oweStatus = get(data, 'self.oweStatus', '');
      const urlLink = get(
        data,
        'self.referralLinks[0].link',
        'Looks like we ran into an issue creating your referral link. Please contact support for assistance.'
      );
      setReferralUrl(urlLink);
      const isVip = positionStatus === 'BUILDER' && oweStatus === 'VIP';
      setVpAndBuilder(isVip);
    }
  }, [data]);
  return (
    <Wrapper>
      <Header>
        <span>Personal Referral Link</span> <LinkOutlinedIcon />
      </Header>
      <Container>
        <Content>
          Put us to work for you! Take advantage of everything Drippl has to
          offer with this simple but powerful link.
        </Content>
        <ButtonClean onClick={handleTogglePopUp}>How does it work?</ButtonClean>
        {vipAndBuilder ? (
          <>
            <ButtonMain onClick={setCopied}>
              <span>
                {isLinkCopied
                  ? 'Link Copied to Clipboard'
                  : 'Click to Copy Link'}
              </span>
              <FileCopyOutlinedIcon fontSize="small" />
            </ButtonMain>
            <TextLink id="referralLink">{referralUrl}</TextLink>
          </>
        ) : (
          <ButtonMain onClick={handleGetVip}>
            <span>Get VIP</span>
          </ButtonMain>
        )}
      </Container>
      <NotificationModal isOpen={isOpen} handleClose={handleTogglePopUp} />
    </Wrapper>
  );
};

export default PersonalLink;
