import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'pages/TheFlow/Steps/Cards/styles';
import StepBox from 'pages/TheFlow/Steps/StepBox';
import UpdateMembershipModal from 'pages/TheFlow/Steps/UpdateMembershipModal';
import PrepareToShareModal from 'pages/TheFlow/Steps/PrepareToShareModal';
import ValidateDoTerraIDModal from 'pages/TheFlow/Steps/ValidateDoTerraIDModal';
import useReactRouter from 'use-react-router';
import { POSITION_STATUS, MEMBER_STATUS } from 'config/constants';

import exploreImg from 'assets/img/steps/explore.png';
import stillExploringImg from 'assets/img/steps/stillexploring.png';
import tryProductsImg from 'assets/img/steps/tryproducts.png';
import confirmMemberImg from 'assets/img/steps/confirmmembership.png';
import useEmLoveEmImg from 'assets/img/steps/use-em-love-em.png';
import prepareShareImg from 'assets/img/steps/preparetoshare.png';
import readyToBuildImg from 'assets/img/steps/readytobuild.png';
import upgradeMemberImg from 'assets/img/steps/upgrademembership.png';
import exploreImage from 'assets/img/steps/use-em-love-em.png';
import awesomeRewardsImg from 'assets/img/steps/awesomerewards.png';
import vipMemberImg from 'assets/img/steps/vip-membership.png';
import { useAuth0 } from 'context/auth';

const Cards = ({
  doTerraStatus,
  oweStatus,
  handleUpdatePositionStatus,
  videosInfo,
  referralId,
}) => {
  const { http } = useAuth0();
  const { history } = useReactRouter();
  const [isOpenMembershipModal, setIsOpenMembershipModal] = useState(false);
  const [isOpenPrepareToShareModal, setIsOpenPrepareToShareModal] = useState(
    false
  );
  const [isOpenDoterrIDModal, setIsOpenDoterrIDModal] = useState(false);

  const {
    EXPLORING,
    PENDING_CUSTOMER,
    CUSTOMER,
    PENDING_BUILDER,
    BUILDER,
  } = POSITION_STATUS;

  const { MEMBER, VIP } = MEMBER_STATUS;

  const handleStepOne = async () => {
    if (doTerraStatus === PENDING_CUSTOMER) {
      return await handleUpdatePositionStatus(EXPLORING);
    }
    history.push('/the-lab');
  };

  const handleStepFour = async () => {
    history.push('/the-lab');
  };

  const handleStepFive = async () => {
    //send email
    http.post(`/send-mail`,{
      input:{
        mailType:'readyToUpgrade'
      }
    })
    setIsOpenPrepareToShareModal(true);
  };

  const handleStepSix = async () => {
    history.push('/the-lab');
  };

  const handleStepSeven = async () => {
    history.push('/lrp');
  };

  const handleUpdateMembership = () => {
    setIsOpenMembershipModal(true);
  };

  const handleStepTwo = async () => {
    if (referralId) {
      return history.push('/wholesale-account');
    }
    setIsOpenDoterrIDModal(true);
  };

  const handleStepThirteen = async () => {
    await handleUpdatePositionStatus(CUSTOMER);
  };

  const handleStepEight = () => {
    window.location.assign('https://login.doterra.com/us/en-us/sign-in');
  };

  const handleStepNine = () => {
    history.push('/confirm-wellness-advocate-status');
  };

  const handleStepTen = () => {
    history.push('/become-vip');
  };

  const handleStepEleven = () => {
    history.push('/the-lab');
  };

  const preMemberCards = status => {
    switch (status) {
      case EXPLORING:
        return (
          <>
            <StepBox {...STEPS[1]} />
            <StepBox {...STEPS[2]} />
          </>
        );
      case PENDING_CUSTOMER:
        return (
          <>
            <StepBox {...STEPS[3]} />
            <StepBox {...STEPS[0]} />
          </>
        );
      default:
        return <></>;
    }
  };

  const memberCards = status => {
    switch (status) {
      case CUSTOMER: // page 3
        return (
          <>
            <StepBox {...STEPS[4]} />
            <StepBox {...STEPS[5]} />
          </>
        );
      case PENDING_BUILDER: // page 4
        return (
          <>
            <StepBox {...STEPS[9]} />
            <StepBox {...STEPS[13]} />
          </>
        );
      case BUILDER: // page 5
        return (
          <>
            <StepBox {...STEPS[6]} />
            <StepBox {...STEPS[10]} />
          </>
        );
      case EXPLORING: // page 6
        return (
          <>
            <StepBox {...STEPS[11]} />
            <StepBox {...STEPS[12]} />
          </>
        );
      default:
        return <></>;
    }
  };

  const vipCards = () => <></>;

  const renderSteps = () => {
    if (oweStatus === MEMBER) {
      return memberCards(doTerraStatus);
    }
    if (oweStatus === VIP) {
      return vipCards();
    }
    return preMemberCards(doTerraStatus);
  };

  const STEPS = {
    0: {
      title: 'Still exploring?',
      description:
        'Need a little more time before setting up a customer account? No problem! Just click to go back.',
      imageUrl: stillExploringImg,
      buttonLabel: `Go back to explore!`,
      handleButton: handleStepOne,
      isInvertedColour: true,
    },
    1: {
      title: 'Explore.',
      description:
        'Why dōTERRA? Why essential oils? Why NOW? Get answers to all of your questions in The Lab!',
      imageUrl: exploreImage,
      buttonLabel: `Take me to The Lab!`,
      handleButton: handleStepOne,
      isVideoCard: true,
      videoInfo: videosInfo.explore,
    },
    2: {
      title: 'Try the products.',
      description:
        'Time to put the products to the test! Check out a variety of great starter kits and get 25% off.',
      imageUrl: tryProductsImg,
      buttonLabel: 'Show me the kits!',
      handleButton: handleStepTwo,
    },
    3: {
      title: 'Confirm membership.',
      description:
        'Were you successful in setting up your customer account? Update your dōTERRA membership status now to continue!',
      imageUrl: confirmMemberImg,
      buttonLabel: 'Confirm my membership!',
      handleButton: handleUpdateMembership,
    },
    4: {
      title: 'Use ‘em to love ‘em.',
      description:
        'I’ve got my oils… now what?” A successful dōTERRA business starts by simply using your oils and making them a part of your life. ',
      imageUrl: useEmLoveEmImg,
      buttonLabel: 'Learn to love your loot!',
      handleButton: handleStepFour,
      isVideoCard: true,
      videoInfo: videosInfo.learn,
    },
    5: {
      title: 'Prepare to share.',
      description:
        'With Drippl, you’ve got everything you need to start building your business! Upgrade your account to Wellness Advocate for FREE with ZERO commitment!',
      imageUrl: prepareShareImg,
      buttonLabel: 'I’m ready to upgrade my account!',
      handleButton: handleStepFive,
    },
    6: {
      title: 'Get ready to build.',
      description:
        "Learn fast-start steps to setting up your business, how to start thinking about building your team, and the daily habits you'll need for succes.",
      imageUrl: readyToBuildImg,
      buttonLabel: 'Check out The Lab!',
      handleButton: handleStepSix,
      isVideoCard: true,
      videoInfo: videosInfo.build,
    },
    7: {
      title: 'Earn awesome rewards.',
      description:
        'Setting up your Loyalty Rewards Program (LRP) will enable you to qualify for commissions and give you the opportunity to try new products and keep your must-haves stocked.',
      imageUrl: awesomeRewardsImg,
      buttonLabel: 'Set up my LRP!',
      handleButton: handleStepSeven,
    },
    8: {
      title: 'Upgrade to Wellness Advocate.',
      description:
        'Upgrade your dōTERRA account to Wellness Advocate so you can begin sharing products, earning commissions, and building your team.',
      imageUrl: upgradeMemberImg,
      buttonLabel: 'Upgrade my dōTERRA account!',
      handleButton: handleStepEight,
    },
    9: {
      title: 'Confirm builder status.',
      description:
        'Before we begin automating your business, let’s verify that you are a Wellness Advocate and that we have your correct dōTERRA ID.',
      imageUrl: confirmMemberImg,
      buttonLabel: 'Confirm my status!',
      handleButton: handleStepNine,
    },
    10: {
      title: 'Get your VIP Membership.',
      description:
        'Are you ready to put Drippl to work for you? Become a VIP Member and start building both efficiently and fearlessly!',
      imageUrl: vipMemberImg,
      buttonLabel: 'Make me a VIP!',
      handleButton: handleStepTen,
    },
    11: {
      title: 'Explore.',
      description:
        'Take a look at what your contacts will discover in The Lab - our online dōTERRA education experience.',
      imageUrl: exploreImg,
      buttonLabel: 'Take me to The Lab!',
      handleButton: handleStepEleven,
      isVideoCard: true,
      videoInfo: videosInfo.explore,
    },
    12: {
      title: 'Confirm membership.',
      description:
        'To get started with Drippl, let us know your current dōTERRA membership status.',
      imageUrl: confirmMemberImg,
      buttonLabel: 'Confirm my membership!',
      handleButton: handleUpdateMembership,
    },
    13: {
      title: 'Still testing the products?',
      description:
        "If you haven't upgraded your account to Wellness Advocate and still want time to test the products, no problem! Let us know by setting your status back to Customer.",
      imageUrl: stillExploringImg,
      buttonLabel: 'Set my status to Customer',
      handleButton: handleStepThirteen,
      isInvertedColour: true,
    },
  };

  return (
    <Container>
      {renderSteps()}
      <UpdateMembershipModal
        isOpen={isOpenMembershipModal}
        handleClose={() => setIsOpenMembershipModal(false)}
      />
      <PrepareToShareModal
        isOpen={isOpenPrepareToShareModal}
        handleClose={() => setIsOpenPrepareToShareModal(false)}
      />
      <ValidateDoTerraIDModal
        isOpen={isOpenDoterrIDModal}
        handleClose={() => setIsOpenDoterrIDModal(false)}
      />
    </Container>
  );
};

Cards.propTypes = {
  doTerraStatus: PropTypes.string.isRequired,
  oweStatus: PropTypes.string.isRequired,
  handleUpdateMemberStatus: PropTypes.func,
  handleUpdatePositionStatus: PropTypes.func,
  videosInfo: PropTypes.shape({
    explore: PropTypes.object,
    learn: PropTypes.object,
    build: PropTypes.object,
  }),
};

export default Cards;
