import styled, { css } from 'styled-components';
import ButtonDefault from 'ui/Button/Default';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import YouTubeIcon from '@material-ui/icons/YouTube';

export const Container = styled.div`
  ${({ theme, isVideoCard }) => css`
    position: relative;
    width: 100%;
    background-color: ${isVideoCard ? theme.colors.white : '#fffdf4'};
    border-radius: 25px;
    border: ${isVideoCard
      ? '2px dashed rgba(0, 0, 0, 0.15)'
      : '1px solid rgba(0, 0, 0, 0.08)'};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 10px 15px;
    margin-bottom: 100px;
    @media (min-width: 600px) {
      flex-direction: row;
      align-items: flex-start;
      margin-top: 50px;
      width: 100%;
      padding: 30px 40px;
      margin-bottom: 0;
    }
  `};
`;

export const Left = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
    padding-top: 6ch;
    @media (min-width: 600px) {
      padding-top: 0;
      padding: 0 7ch;
    }
  `};
`;

export const Right = styled.div`
  ${() => css`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 10px;
    width: 100%;
    @media (min-width: 600px) {
      width: auto;
      padding: 0;
      align-self: center;
    }
  `};
`;

export const Title = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 800;
  font-size: 20px;
  line-height: 25px;
  @media (min-width: 600px) {
    margin-bottom: 20px;
    font-size: 26px;
    line-height: 29px;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
  }
`;

export const Description = styled.div`
  margin-bottom: 15px;
  color: #0e0d0d;
  font-size: 14px;
  line-height: 18px;
  font-weight: 300;
  @media (min-width: 600px) {
    font-size: 15px;
    font-weight: 500;
  }
`;

export const Button = styled(ButtonDefault)`
  background-color: ${({ isSemiActive }) =>
    isSemiActive ? 'rgba(94, 214, 213, 0.5)' : null};
  border: 3px solid white;
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-out;
  border-radius: 40px;
  padding: 14px;
  @media (min-width: 600px) {
    width: 80%;
    height: 55px;
    margin-bottom: 0;
  }
`;

export const ButtonInverted = styled(ButtonDefault)`
  ${({ theme }) => css`
    background-color: white;
    border: 3px solid ${theme.colors.primary};
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease-out;
    border-radius: 40px;
    padding: 14px;
    color: ${theme.colors.primary};
    @media (min-width: 600px) {
      width: 80%;
      height: 55px;
      margin-bottom: 0;
    }
  `}
`;

export const ButtonVideo = styled(ButtonDefault)`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    border: 3px solid ${theme.colors.black};
    color: ${theme.colors.black};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    padding: 4px;
    &:hover {
      background-color: ${theme.colors.white};
    }
    @media (min-width: 600px) {
      width: 80%;
      height: 55px;
    }
  `};
`;

export const VideoOutlineIcon = styled(YouTubeIcon)`
  && {
    font-size: 40px;
    padding-left: 10px;
  }
`;

export const PlayOutlineIcon = styled(VideocamOutlinedIcon)`
  && {
    font-size: 42px;
    padding-left: 10px;
    padding-top: 2px;
  }
`;

export const ImageContainer = styled.div`
  ${() => css`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 20%;
    top: -60px;
    width: auto;
    height: auto;
    @media (min-width: 600px) {
      left: -75px;
      top: 10%;
    }
  `};
`;

export const Image = styled.img`
  overflow: visible;
  border: none;
  height: 60%;
  width: 60%;
`;

export const DisableOverlay = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    background-color: ${theme.colors.lightGrey};
    @media (min-width: 600px) {
    }
  `};
`;
