import React, { useState, Fragment, useEffect } from 'react';
import { Col, Row, PageContent } from 'ui/layouts';
import {
  BoldText,
  ChooseRow,
  ChooseBox,
  ChooseNumber,
  ChooseText,
  MiddleText,
  ProductBox,
  Product,
  ProductLabel,
  ProductButton,
  ProductImage,
  ProductDescription,
  PriceBox,
  Price,
  PriceDescount,
  ProductPriceDescount,
  Text,
  SeeDetails,
  WrapperVideo,
  WarningBox,
} from 'pages/Wholesale/styles';
import PageTitle from 'ui/Text/PageTitle';
import PageSubtitle from 'ui/Text/PageSubtitle';
import H1 from 'ui/Text/H1';
import H3 from 'ui/Text/H3';
import GoBackToTheFlowPage from 'ui/GoBackToTheFlowPage';
import useWholesale from 'pages/Wholesale/data/useWhosale';
import ModalProductDetails from 'pages/Wholesale/ModalProductDetails';
import useReactRouter from 'use-react-router';
import ReactPlayer from 'react-player';
import { POSITION_STATUS } from 'config/constants';
import useTheFlow from 'pages/TheFlow/data/useTheFlow';
import { useAuth0 } from 'context/auth';

const Wholesale = () => {
  const { history } = useReactRouter();
  const { http } = useAuth0();
  const { handleUpdatePositionStatus } = useTheFlow();
  const { productList } = useWholesale();

  const [isOpen, setIsOpen] = useState(false);

  const [product, setProduct] = useState({});

  const handleCloseModal = () => setIsOpen(false);

  const handleOpenDetails = productDetails => {
    setProduct(productDetails);
    setIsOpen(true);
  };

  const 
  handleBuyNow = async title => {
    //send email
    http.post(`/send-mail`,{
      input:{
        mailType:'kitClicked',
        mailParams:{
          kitName: title
        }
      }
    })

    await handleUpdatePositionStatus(POSITION_STATUS.PENDING_CUSTOMER);
    history.push('/the-flow');
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <Fragment>
      <Col>
        <PageTitle>Put the Products to the Test</PageTitle>
        <PageSubtitle>
          Experience the Potency, Purity, and Power Yourself!
        </PageSubtitle>
      </Col>
      <PageContent>
        <GoBackToTheFlowPage />
        <H3 center primary>
          Now that you know a little about dōTERRA and essential oils, you’re
          here for one of two reasons:
        </H3>

        <ChooseRow>
          <ChooseBox>
            <ChooseNumber>1</ChooseNumber>
            <ChooseText>
              You’re looking to build a business of your own inside a rapidly
              growing company with a reputation that can’t be beat...
            </ChooseText>
          </ChooseBox>
          <MiddleText>OR</MiddleText>
          <ChooseBox>
            <ChooseNumber>2</ChooseNumber>
            <ChooseText>
              You want to put these products to the test and get the
              highest-quality oils at a discounted price.
            </ChooseText>
          </ChooseBox>
        </ChooseRow>
        <Row isCentralized>
          <Text center fontSize={20}>
            Either way, <BoldText>you’re in the right place!</BoldText>
          </Text>
        </Row>
        <Col>
          <Row isCentralized>
            <H1 primary isUppercase>
              Getting Started is Easy
            </H1>
          </Row>
          <Row isCentralized>
            <WrapperVideo>
              <ReactPlayer
                height="100%"
                width="100%"
                url="https://vimeo.com/428335776"
              />
            </WrapperVideo>
          </Row>
          <Text center>
            While you can pay a $35 enrollment fee to get a wholesale account
            (sorta like a Costco or Sam’s Club membership), we suggest you start
            with an Enrollment Kit.
          </Text>
          <Text center>
            Why? Enrollment kits get you products at 25-55% below retail price
            while waiving your enrollment fee! AND you receive 25% off all
            dōTERRA products for a year!
          </Text>
        </Col>

        <ProductBox>
          {productList.map(itemProduct => {
            const {
              isMostPopular,
              title,
              productImg,
              retailPrice,
              wholesalePrice,
              savePrice,
              productUrl,
              productID,
            } = itemProduct;
            return (
              <Product key={title}>
                {isMostPopular && <ProductLabel>most popular</ProductLabel>}
                <ProductImage src={productImg} hasMarginTop={!isMostPopular} />
                <ProductDescription>{title}</ProductDescription>
                <PriceBox>
                  <Price>{retailPrice}</Price>
                  <PriceDescount>{wholesalePrice}</PriceDescount>
                </PriceBox>
                <ProductPriceDescount>Save {savePrice}!</ProductPriceDescount>
                <SeeDetails onClick={() => handleOpenDetails(itemProduct)}>
                  Peek Inside
                </SeeDetails>
                <ProductButton
                  onClick={() => handleBuyNow(title)}
                  href={productUrl}
                  target="_blank"
                  rel="noopener noreferrer">
                  Buy Now
                </ProductButton>
              </Product>
            );
          })}
        </ProductBox>
        <WarningBox>
          If you already have a dōTERRA membership{' '}
          <span onClick={handleBuyNow}>click here</span>
        </WarningBox>
      </PageContent>
      <ModalProductDetails
        isOpen={isOpen}
        handleClose={handleCloseModal}
        product={product}
      />
    </Fragment>
  );
};

export default Wholesale;
