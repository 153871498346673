import styled, { css } from 'styled-components';
import Button from 'ui/Button/Default';

export const ContainerModalCancelSub = styled.form`
  ${() => css`
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    @media (min-width: 600px) {
    }
  `};
`;

export const Title = styled.div`
  ${() => css`
    color: #c22620;
    text-align: center;
    font-size: 26px;
    font-weight: 700;
    line-height: 28px;
    margin: 20px;
    @media (min-width: 600px) {
    }
  `};
`;

export const SubTitle = styled.div`
  ${() => css`
    color: black;
    font-size: 20px;
    line-height: 22px;
    font-weight: 600;
    text-align: center;
    padding: 20px 0;
    @media (min-width: 600px) {
    }
  `};
`;

export const Text = styled.div`
  ${() => css`
    text-align: center;
    color: rgba(0, 0, 0, 0.7);
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 20px;
    padding: 0 80px;
    @media (min-width: 600px) {
    }
  `};
`;

export const TextLeft = styled(Text)`
  ${({ theme }) => css`
    text-align: left;
    padding: 0 40px 30px;
    border-bottom: 1px solid ${theme.colors.border};
    @media (min-width: 600px) {
    }
  `};
`;

export const WrapperInput = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-top: 30px;
    padding-left: 45px;
    > div {
      margin-bottom: 20px;
    }
    @media (min-width: 600px) {
    }
  `};
`;

export const TextAreaInput = styled.textarea`
  ${({ theme }) => css`
    margin-bottom: 20px;
    height: 100px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid ${theme.colors.border};
    @media (min-width: 600px) {
      width: 400px;
      margin-left: 28px;
    }
  `};
`;

export const ButtonCancel = styled(Button)`
  ${({ theme }) => css`
    color: white;
    background-color: #c22620;
    margin: 10px 0;
    width: 60%;
    align-self: center;
    height: 58px;
    font-size: 22px;
    font-weight: 700;
    @media (min-width: 600px) {
    }
  `};
`;

export const GaveUpLink = styled(Text)`
  ${({ theme }) => css`
    font-weight: 500;
    padding-top: 10px;
    cursor: pointer;
    @media (min-width: 600px) {
    }
  `};
`;

export const CancelLink = styled(Text)`
  ${() => css`
    font-weight: 500;
    padding-top: 10px;
    cursor: pointer;
    color: red;
    @media (min-width: 600px) {
    }
  `};
`;
