import React, { memo } from 'react';
import { Container, Avatar, Button, Text } from 'ui/ContactBar/styles';
import useContactBar from 'ui/ContactBar/data/useContactBar';

const ContactBar = () => {
  const {
    data: { fullName, email, profilePicture, phoneNumber },
  } = useContactBar();

  const onClick = () => window.open(`mailto:${email}`, '_blank');

  return (
    <Container showContact>
      {profilePicture && <Avatar src={profilePicture} />}
      {fullName && (
        <Text>
          Referred by <span>{fullName}</span>
        </Text>
      )}
      {phoneNumber && (
        <Text>
          Text or Call: <span>{phoneNumber}</span>
        </Text>
      )}
      {email && <Button onClick={onClick}>Send Me a Message</Button>}
    </Container>
  );
};

export default memo(ContactBar);
