import styled, { css } from 'styled-components';
import ButtonDefault from 'ui/Button/Default';

export const SectionBottom = styled.div`
  ${() => css`
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: column;

    @media (min-width: 600px) {
      flex-direction: row;
      justify-content: space-between;
    }
  `};
`;

export const UploadFormWrapper = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    order: 2;
    @media (min-width: 600px) {
      order: 1;
    }
  `};
`;

export const Button = styled(ButtonDefault)`
  ${() => css`
    padding: 10px;
    align-self: flex-end;
    font-size: 14px;
    width: 100%;
    text-transform: uppercase;
    order: 1;
    @media (min-width: 600px) {
      order: 2;
      font-size: 16px;
      width: auto;
    }
  `};
`;

export const Avatar = styled.div`
  height: 150px;
  width: 150px;
  border-radius: 50%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${({ src }) => `url(${src})`};
  margin-bottom: 30px;
  align-self: center;
  @media (min-width: 600px) {
    align-self: left;
  }
`;

export const UploadAvatarButton = styled.div`
  ${({ theme }) => css`
    padding: 10px;
    border: none;
    border-radius: 4px;
    text-decoration: none;
    border: 0;
    cursor: pointer;
    outline: none;
    background-color: ${theme.colors.primary};
    text-align: center;
    color: white;
    font-weight: bold;
    @media (min-width: 600px) {
    }
  `};
`;
