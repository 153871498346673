import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Icon(props) {
  return (
    <SvgIcon viewBox="0 0 360 360" className={props.className}>
      <path
        className="st0"
        d="M134.3,81.1c-4.9-1-9.5-1.4-13.6-2.9c-10.2-3.7-16.7-15-14.9-24.8c2.4-12.7,11.2-20.9,23.2-20.9
			c34.7-0.1,69.3-0.1,104,0c11.3,0,21.4,9.8,22.4,21.3c1.1,11.9-6.8,23.2-18,25.5c-3.4,0.7-6.8,0.9-10.6,1.4c0,1.1,0,2.3,0,3.5
			c0.1,17.4-0.4,34.8,0.3,52.2c0.8,19.8,7.5,37.9,18.1,54.5c17,26.8,34,53.5,51.3,80c7.3,11.1,8.2,22.3,2.4,34.1
			c-6.1,12.3-16.1,19.4-29.8,19.8c-18.7,0.6-37.4,0.2-56.1,0.2c-14.7,0-29.4,0.2-44.1,0.2c-16.7,0-33.4-0.1-50.1-0.2
			c-7.9,0-15.8-0.1-23.7,0c-13.7,0.1-24.9-4.9-31.8-17.1c-6.9-12-6.5-24.4,1-36.1c17-26.8,34-53.5,51.3-80.1
			c12.4-19,18.8-39.7,19-62.4c0.2-15.5,0-31.1,0-46.6C134.5,82.2,134.4,81.5,134.3,81.1z M180.5,309.6
			C180.5,309.6,180.5,309.6,180.5,309.6c4.5,0,9.1,0,13.6,0c25.5-0.1,51.1,0.1,76.6-0.4c13.7-0.3,22-17,14.6-28.5
			c-12.7-19.7-25.5-39.2-38.1-59c-2.2-3.4-3.9-3.5-7.3-2c-19.3,8.4-39.2,10.7-59.7,4.5c-19-5.8-38.2-7-57.7-2.7
			c-3.7,0.8-8.7,1.4-10.4,3.9c-12.6,18.4-24.7,37.2-36.6,56.1c-4,6.5-3.5,13.5,1.3,19.9c4.6,6.1,10.7,8.2,18.2,8.2
			C123.5,309.6,152,309.6,180.5,309.6z M124.8,205.6c4-0.3,7-0.5,10-0.9c14.4-1.8,28.6-0.8,42.4,3.3c14.5,4.3,29,5.8,43.8,2.4
			c5.5-1.3,10.7-3.4,16.5-5.2c-1.2-1.9-1.9-2.9-2.5-3.9c-15.4-22.2-22.8-46.9-22.7-73.9c0.1-14.2,0-28.4,0-42.6c0-1.4,0-2.9,0-4.6
			c-2.5,0-4.5,0-6.4,0c-4.9-0.1-7.9-3.2-7.8-7.8c0.1-4.4,3.1-7,7.9-7.1c9,0,17.9,0.1,26.8-0.3c2.3-0.1,5.1-1.7,6.8-3.4
			c2.7-2.6,2.8-6.2,1.2-9.7c-2.1-4.5-6-5.2-10.4-5.2c-33,0-66.1,0-99.1,0c-0.7,0-1.3,0-2,0.1c-5.2,0.5-8.4,4.2-8.3,9.4
			c0.1,5.2,3.6,8.8,8.8,9c1.6,0.1,3.2,0,4.8,0c13,0,25.9-0.3,38.9,0.1c2.2,0.1,5.1,2.1,6.3,4.1c3,5.1-0.6,10.4-7,10.5
			c-7.6,0.2-15.1,0-22.9,0c-0.1,1.4-0.2,2.4-0.2,3.3c0,16.9,0.1,33.7-0.1,50.6c0,4.5-0.6,9.1-1.3,13.5c-3,19.5-10.5,37.2-21,53.7
			C126.4,202.4,125.8,203.7,124.8,205.6z"
      />
    </SvgIcon>
  );
}

Icon.propTypes = {
  className: PropTypes.string,
};
