import React, { forwardRef, useCallback } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { makeStyles } from '@material-ui/styles';
import { List, ListItem, Button } from '@material-ui/core';
import { Container, Link } from 'ui/layouts/Minimal/Sidebar/SideMenu/styles';
import { useAuth0 } from 'context/auth';
import DripplLogo from 'assets/img/logos/drippl_logo.png';
import StatusChanged from 'ui/StatusChange';

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    color: 'white',
  },
  button: {
    color: 'white',
    padding: '20px 32px 20px 20px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
  },
  icon: {
    color: 'white',
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  active: {
    color: 'white',
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor: '#00b6b4',
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const SideMenu = props => {
  const { pages, className, ...rest } = props;
  const classes = useStyles();
  const { logout } = useAuth0();

  // const loginUrl =
  //   process.env.NODE_ENV === 'production'
  //     ? 'https://owe.webflow.io/drippl-home'
  //     : window.location.origin;

  const loginUrl = process.env.REACT_APP_LOGIN_URL || window.location.origin;

  const handleLogout = useCallback(() => {
    logout({
      returnTo: loginUrl,
    });
  }, [loginUrl, logout]);

  const handleSupportClick = () => {
    window.open(`mailto:support@drippl.com`, '_blank');
  };

  return (
    <Container>
      <Link to="/">
        <img alt="Logo" height="40px" src={DripplLogo} />
      </Link>
      <List {...rest} className={clsx(classes.root, className)}>
        {pages.map(page => (
          <ListItem className={classes.item} disableGutters key={page.title}>
            <Button
              activeClassName={classes.active}
              className={classes.button}
              component={CustomRouterLink}
              to={page.href}>
              <div className={classes.icon}>{page.icon}</div>
              {page.title}
            </Button>
          </ListItem>
        ))}
        <Button className={classes.button} onClick={handleSupportClick}>
          <div className={classes.icon}>
            <HelpOutlineIcon />
          </div>
          Contact Support
        </Button>
        <Button className={classes.button} onClick={handleLogout}>
          <div className={classes.icon}>
            <ExitToAppIcon />
          </div>
          Logout
        </Button>
      </List>
      <StatusChanged />
    </Container>
  );
};

SideMenu.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired,
};

export default SideMenu;
