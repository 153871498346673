import React from 'react';
import styled from 'styled-components';
import ButtonDefault from 'ui/Button/Default';
import useReactRouter from 'use-react-router';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0 0 30px;
  button {
    padding: 10;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    svg {
      position: absolute;
      left: 34%;
    }
  }
  @media (min-width: 600px) {
    margin: 0 10px 40px;
    button {
      width: 15%;
      svg {
        position: absolute;
        left: 27px;
      }
    }
  }
`;

const GoBackToTheFlowPage = () => {
  const { history } = useReactRouter();
  const handleClick = () => history.push('/the-flow');
  return (
    <Container>
      <ButtonDefault onClick={handleClick}>
        <ArrowLeftIcon size="small" />
        Go Back
      </ButtonDefault>
    </Container>
  );
};

export default GoBackToTheFlowPage;
