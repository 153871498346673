import product1 from 'assets/img/productList/product1.jpg';
import product2 from 'assets/img/productList/product2.jpg';
import product3 from 'assets/img/productList/product3.jpg';
import product4 from 'assets/img/productList/product4.jpg';
import product5 from 'assets/img/productList/product5.jpg';
import product6 from 'assets/img/productList/product6.jpg';

const disclaimer =
  '*These statements have not been evaluated by the Food and Drug Administration. This product is not intended to diagnose, treat, cure, or prevent any disease.';
const subTitle = '$35 Wholesale Membership Fee Waived';

const kitOne = {
  title: 'Simple Solutions Kit',
  productImg: product1,
  retailPrice: '$140',
  wholesalePrice: '$105',
  savePrice: '$35',
  productUrl:
    'https://www.doterra.com/US/en/create-cart/enrollment?OwnerID=doTERRAID&salesOrg=USOTG&acctType=WC&60211288=1',
  details: {
    subTitle,
    items: [
      '<strong>Description</strong>',
      '<strong>Lemon (15 mL)</strong>—provides cleansing and digestive benefits and supports healthy respiratory function.* When diffused, it is very uplifting and energizing and has been shown to help improve mood.',
      '<strong>doTERRA On Guard® (15 mL)</strong>—protects against environmental and seasonal threats with essential oils known for their positive effects on the immune system when ingested.*',
      '<strong>doTERRA Serenity® (15 mL)</strong>',
      '<strong>doTERRA Deep Blue® Rub</strong>—soothes and cools topically where needed before and after your workout.',
    ],
    disclaimer,
  },
};

const kitTwo = {
  title: 'Healthy Start Kit',
  productImg: product2,
  retailPrice: '$213',
  wholesalePrice: '$160',
  savePrice: '$53',
  isMostPopular: true,
  productUrl:
    'https://www.doterra.com/US/en/create-cart/enrollment?OwnerID=doTERRAID&salesOrg=USOTG&acctType=WC&60211452=1',
  details: {
    description:
      'Transforming your mind, body, and home has never been easier with essential oils and essential oil infused products. With doTERRA, you can be confident that your essential oils are safe, pure, and effective.',
    subTitle,
    items: [
      '<strong>Description</strong>',
      '(5 mL bottles)',
      '<strong>Lavender</strong>—calms and relaxes when taken internally. Add to bath water or apply to the temples and the back of the neck to reduce away stress. Use to assist with restful sleep and stress management.',
      '<strong>Lemon</strong>—provides cleansing and digestive benefits and supports healthy respiratory function.* When diffused, it is very uplifting and energizing and has been shown to help improve mood.',
      '<strong>Peppermint</strong>—helps alleviate occasional stomach upset and promotes healthy respiratory function when taken internally.* Repels bugs naturally. Place one drop in palm of hand and inhale for a refreshing pick-me-up.',
      '<strong>Tea Tree</strong>—renowned for its cleansing and rejuvenating effect on the skin. Diffuse throughout the home to purify and freshen the air.',
      '<strong>Oregano</strong>—used as a powerful cleansing and purifying agent. Supports a healthy immune system, healthy digestion, and respiratory function when used internally. Offers powerful antioxidants when ingested.*',
      '<strong>Frankincense</strong>—supports healthy cellular, immune, nervous, and digestive function when used internally.* When inhaled or diffused, it promotes feelings of peace, relaxation, satisfaction, and overall wellness.',
      '<strong>Deep Blue®</strong>—soothes and cools topically where needed before and after your workout.',
      '<strong>doTERRA Breathe®</strong>—an invigorating blend that promotes feelings of clear and easy breathing.*',
      '<strong>doTERRA On Guard®</strong>—protects against environmental and seasonal threats with essential oils known for their positive effects on the immune system when ingested.*',
      '<strong>DigestZen®</strong>—supports digestion and optimizes your gastrointestinal tract*',
      '<strong>Other Products:</strong>',
      'Brevi® Stone Diffuser',
    ],
    disclaimer,
  },
};

const kitThree = {
  title: 'Healthy Habits Kit',
  productImg: product3,
  retailPrice: '$260',
  wholesalePrice: '$195',
  savePrice: '$65',
  productUrl:
    'https://www.doterra.com/US/en/create-cart/enrollment?OwnerID=doTERRAID&salesOrg=USOTG&acctType=WC&60209837=1',
  details: {
    subTitle: 'Description',
    items: [
      '<strong>doTERRA On Guard® (5 mL)</strong>—protects against environmental and seasonal threats with essential oils known for their positive effects on the immune system when ingested.*',
      '<strong>doTERRA Balance® (5 mL)</strong>—creates a sense of calm and well-being, tranquility and relaxation.',
      '<strong>Frankincense (5 mL)</strong>—supports healthy cellular, immune, nervous, and digestive function when used internally.* When inhaled or diffused, it promotes feelings of peace, relaxation, satisfaction, and overall wellness.',
      '<strong>Lemon (5 mL)</strong>—provides cleansing and digestive benefits and supports healthy respiratory function.* When diffused, it is very uplifting and energizing and has been shown to help improve mood.',
      '<strong>Lavender (5 mL)</strong>—calms and relaxes when taken internally. Add to bath water or apply to the temples and the back of the neck to reduce away stress. Use to assist with restful sleep and stress management.',
      '<strong>Deep Blue® Rub</strong>—soothes and cools topically where needed before and after your workout.',
      '<strong>doTERRA Lifelong Vitality Pack®</strong> includes xEO Mega®, Alpha CRS®+, Microplex VMz®— maximizes your daily nutrient intake.',
      '<strong>DigestZen® Supplements</strong> includes DigestZen TerraZyme® and PB Assist®+—supports digestion and optimizes your gastrointestinal tract*',
    ],
    disclaimer,
  },
};

const kitFour = {
  title: 'Healthy Essentials Kit',
  productImg: product4,
  retailPrice: '$332',
  wholesalePrice: '$249',
  savePrice: '$83',
  productUrl:
    'https://www.doterra.com/US/en/create-cart/enrollment?OwnerID=doTERRAID&salesOrg=USOTG&acctType=WC&60213144=1',
  details: {
    subTitle: '',
    items: [
      '<strong>Description</strong>',
      '<Strong>Adaptiv™</strong>—calming blend helps improve sustained attention*',
      '<Strong>doTERRA Balance®</strong>—creates a sense of calm and well-being, tranquility and relaxation.',
      '<Strong>doTERRA Breathe®</strong>—an invigorating blend that promotes feelings of clear and easy breathing.*',
      '<Strong>Deep Blue®</strong>—soothes and cools topically where needed before and after your workout.',
      '<Strong>Copaiba</strong>, when taken internally, helps support the cardiovascular, immune, digestive, and nervous system',
      '<Strong>Digestzen®</strong>—supports digestion and optimizes your gastrointestinal tract*—supports digestion and optimizes your gastrointestinal tract*—supports digestion and optimizes your gastrointestinal tract*—supports digestion and optimizes your gastrointestinal tract*',
      '<Strong>Lavender</strong>—calms and relaxes when taken internally. Add to bath water or apply to the temples and the back of the neck to reduce away stress. Use to assist with restful sleep and stress management.',
      '<Strong>Lemon</strong>—provides cleansing and digestive benefits and supports healthy respiratory function.* When diffused, it is very uplifting and energizing and has been shown to help improve mood.',
      '<Strong>doTERRA On Guard®</strong>—protects against environmental and seasonal threats with essential oils known for their positive effects on the immune system when ingested.*',
      '<Strong>Peppermint</strong>—helps alleviate occasional stomach upset and promotes healthy respiratory function when taken internally.* Repels bugs naturally. Place one drop in palm of hand and inhale for a refreshing pick-me-up.',
      '<Strong>The Petal® 2.0 Diffuser</strong> is small, user-friendly and can cover 330 square feet',
    ],
    disclaimer,
  },
};

const kitFive = {
  title: 'Healthy Home Kit',
  productImg: product5,
  retailPrice: '$467',
  wholesalePrice: '$350',
  savePrice: '$117',
  productUrl:
    'https://www.doterra.com/US/en/create-cart/enrollment?OwnerID=doTERRAID&salesOrg=USOTG&acctType=WC&60209827=1',
  details: {
    subTitle,
    items: [
      '<strong>Essential Oils (15 mL unless otherwise indicated):</strong>',
      '<strong>Frankincense</strong>—supports healthy cellular, immune, nervous, and digestive function when used internally.* When inhaled or diffused, it promotes feelings of peace, relaxation, satisfaction, and overall wellness.',
      '<strong>Lavender</strong>—calms and relaxes when taken internally. Add to bath water or apply to the temples and the back of the neck to reduce away stress. Use to assist with restful sleep and stress management.',
      '<strong>Lemon</strong>—provides cleansing and digestive benefits and supports healthy respiratory function.* When diffused, it is very uplifting and energizing and has been shown to help improve mood.',
      '<strong>Tea Tree</strong>—renowned for its cleansing and rejuvenating effect on the skin. Diffuse throughout the home to purify and freshen the air.',
      '<strong>Oregano</strong>—used as a powerful cleansing and purifying agent. Supports a healthy immune system, healthy digestion, and respiratory function when used internally. Offers powerful antioxidants when ingested.*',
      '<strong>Peppermint</strong>—helps alleviate occasional stomach upset and promotes healthy respiratory function when taken internally.* Repels bugs naturally. Place one drop in palm of hand and inhale for a refreshing pick-me-up.',
      '<strong>doTERRA Breathe®</strong>—an invigorating blend that promotes feelings of clear and easy breathing.*',
      '<strong>DigestZen®</strong>—supports digestion and optimizes your gastrointestinal tract*',
      '<strong>doTERRA On Guard®</strong>—protects against environmental and seasonal threats with essential oils known for their positive effects on the immune system when ingested.*',
      '<strong>Deep Blue® ( 5mL)</strong>—soothes and cools topically where needed before and after your workout.',
      '<strong>doTERRA On Guard Products:</strong>',
      'doTERRA On Guard Sanitizing Mist',
      'doTERRA On Guard Cleaner Concentrate',
      'doTERRA On Guard Foaming Hand Wash',
      'doTERRA On Guard Foaming Hand Wash Dispenser',
      'doTERRA On Guard Natural Whitening Toothpaste',
      '<strong>Other Products:</strong>',
      'Brevi® Stone Diffuser',
      'doTERRA Salon Essentials® Protecting Shampoo',
      'doTERRA Salon Essentials Smoothing Conditioner',
    ],
    disclaimer,
  },
};

const kitSix = {
  title: 'Natural Solutions Kit',
  productImg: product6,
  retailPrice: '$760',
  wholesalePrice: '$570',
  savePrice: '$190',
  productUrl:
    'https://www.doterra.com/US/en/create-cart/enrollment?OwnerID=doTERRAID&salesOrg=USOTG&acctType=WC&60209826=1',
  details: {
    subTitle,
    items: [
      '<strong>Essential Oils (15 mL unless otherwise specified):</strong>',
      '<strong>PastTense (10 mL)®</strong>—known for their soothing effects on both the mind and body, the essential oils in PastTense quickly, promote feelings of relaxation and calm emotions.',
      '<strong>Lavender</strong>—calms and relaxes when taken internally. Add to bath water or apply to the temples and the back of the neck to reduce away stress. Use to assist with restful sleep and stress management.',
      '<strong>Lemon</strong>—provides cleansing and digestive benefits and supports healthy respiratory function.* When diffused, it is very uplifting and energizing and has been shown to help improve mood.',
      '<strong>Peppermint</strong>—helps alleviate occasional stomach upset and promotes healthy respiratory function when taken internally.* Repels bugs naturally. Place one drop in palm of hand and inhale for a refreshing pick-me-up.',
      '<strong>doTERRA On Guard®</strong>—protects against environmental and seasonal threats with essential oils known for their positive effects on the immune system when ingested.*',
      '<strong>Frankincense</strong>—supports healthy cellular, immune, nervous, and digestive function when used internally.* When inhaled or diffused, it promotes feelings of peace, relaxation, satisfaction, and overall wellness.',
      '<strong>Tea Tree</strong>—renowned for its cleansing and rejuvenating effect on the skin. Diffuse throughout the home to purify and freshen the air.',
      '<strong>Wild Orange</strong>—possesses stimulating and purifying qualities that support healthy immune function when taken internally.  Can also be used on surfaces as a natural cleaner.',
      '<strong>doTERRA Balance®</strong>—creates a sense of calm and well-being, tranquility and relaxation.',
      '<strong>DigestZen®</strong>—supports digestion and optimizes your gastrointestinal tract*',
      '<strong>AromaTouch®</strong>—combines a unique blend of oils known to provide relaxation and comforting effects.  Use to lessen tension and provide an aromatic experience to a soothing massage.',
      '<strong>doTERRA Serenity®</strong>—provides a calming and relaxing experience with its soothing aroma.  Apply one to two drops to hands and inhale throughout the day to help reduce life’s daily stressors, or diffuse at night to help quiet a restless baby or child.*  ',
      '<strong>doTERRA Breathe®</strong>—an invigorating blend that promotes feelings of clear and easy breathing.*',
      '<strong>doTERRA On Guard Collection:</strong>',
      'doTERRA On Guard Beadlets',
      'doTERRA On Guard Toothpaste',
      'doTERRA On Guard Hand Wash with 2 Dispensers',
      'doTERRA On Guard Mouth Wash',
      'doTERRA On Guard Sanitizer Spray',
      'doTERRA On Guard Softgels',
      '<strong>Other Products:</strong>',
      'doTERRA Lifelong Vitality Pack®',
      'DigestZen TerraZyme®',
      'Deep Blue® Rub',
      'Fractionated Coconut Oil',
      'doTERRA Salon Essentials® Protecting Shampoo',
      'doTERRA Salon Essentials® Smoothing Conditioner',
      'doTERRA Spa Hand & Body Lotion',
      'doTERRA Lumo Diffuser',
      'doTERRA Breathe® Vapor Stick',
      'Correct-X®',
      'PB Assist®+',
      'Wooden Box',
    ],
    disclaimer,
  },
};

const kits = [kitOne, kitTwo, kitThree, kitFour, kitFive, kitSix];

export default kits;
