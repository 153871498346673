import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import MaskedInput from 'react-input-mask';
import Input from 'ui/InputText';
import { SectionForm, WrapperInput } from 'pages/Profile/styles';
import useProfile from 'pages/Profile/data/useProfile';
import ModalUploadAvatar from 'ui/ModalUploadAvatar';
import {
  UploadFormWrapper,
  SectionBottom,
  Button,
  Avatar,
  UploadAvatarButton,
} from 'pages/Profile/ProfileForm/styles';

const ProfileForm = ({ data }) => {
  const { register, handleSubmit, errors, setValue } = useForm();
  const { handleSubmitForm } = useProfile();

  const [tel, setTel] = useState('');
  const [inputErrors, setInputErrors] = useState([]);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [userAvatar, setUserAvatar] = useState('');

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOpen = () => setIsOpen(true);

  const onSubmit = async formData => {
    setIsSaveDisabled(true);
    await handleSubmitForm({ ...formData, phone: tel });
  };

  useEffect(() => {
    if (errors) {
      const fieldErros = Object.entries(errors).map(([key]) => key);
      setInputErrors(fieldErros);
    }
  }, [errors]);

  useEffect(() => {
    if (data) {
      const { firstName, lastName, phone } = data;
      setTel(phone);
      setValue([{ firstName }, { lastName }, { phone }]);
    }
  }, [data, register, setValue]);

  useEffect(() => {
    if (data && data.profilePicture) {
      setUserAvatar(data.profilePicture);
    }
  }, [data]);

  return (
    <SectionForm onSubmit={handleSubmit(onSubmit)}>
      <h2>Personal Info</h2>
      <WrapperInput>
        <label>First Name</label>
        <div>
          <Input
            name="firstName"
            hasError={inputErrors.includes('firstName')}
            ref={register({ required: true })}
          />
        </div>
      </WrapperInput>
      <WrapperInput>
        <label>Last Name</label>
        <div>
          <Input
            name="lastName"
            hasError={inputErrors.includes('lastName')}
            ref={register({ required: true })}
          />
        </div>
      </WrapperInput>
      <WrapperInput>
        <label>Email</label>
        <div>
          <Input name="email" isDisabled disabled value={data.email} />
        </div>
      </WrapperInput>
      <WrapperInput>
        <label>Phone Number</label>
        <div>
          <MaskedInput
            value={tel}
            mask="(999) 999-9999"
            alwaysShowMask
            onChange={e => setTel(e.target.value)}>
            {() => (
              <Input
                name="phone"
                inputRef={register()}
                hasError={inputErrors.includes('phone')}
              />
            )}
          </MaskedInput>
        </div>
      </WrapperInput>
      <SectionBottom>
        <UploadFormWrapper>
          {userAvatar && <Avatar src={userAvatar} />}
          <UploadAvatarButton onClick={handleOpen}>
            Update Avatar
          </UploadAvatarButton>
        </UploadFormWrapper>
        <Button
          disabled={isSaveDisabled}
          isDisabled={isSaveDisabled}
          type="submit">
          {isSaveDisabled ? 'saved' : 'update'}
        </Button>
      </SectionBottom>
      <ModalUploadAvatar
        isOpen={isOpen}
        handleClose={handleClose}
        profilePicture={userAvatar}
        setProfileImage={setUserAvatar}
      />
    </SectionForm>
  );
};

export default ProfileForm;
