import styled, { css } from 'styled-components';

export const ContainerCourses = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 10px;
    @media (min-width: 600px) {
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 25px 0 10px;
    }
  `};
`;

export const CourseCard = styled.div`
  ${({ theme, isLocked }) => css`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    margin-bottom: 29px;
    border: 1px solid #e4e4e4;
    transition: all 150ms ease;
    cursor: pointer;
    border-radius: 9px;

    & .video-error {
      display: none;
    }

    :hover {
      border-color: ${theme.colors.primary};
      box-shadow: 0 18px 14px -11px silver;
      transform: translate(0px, -5px);

      & .locker-img {
        display: ${isLocked ? 'none' : 'auto'};
      }
      & .video-error {
        display: ${isLocked ? 'flex' : 'none'};
      }
    }
    @media (min-width: 600px) {
      width: 32%;
    }
  `};
`;

export const WrapperImage = styled.div`
  ${() => css`
    position: relative;
    @media (min-width: 600px) {
    }
  `};
`;

export const ErrorMessage = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    font-size: 18px;
    background-color: white;
    border: 2px solid ${theme.colors.primary};
    border-radius: 12px;
    width: 80%;
    color: ${theme.colors.primary};
    font-weight: bold;
    text-align: center;
    @media (min-width: 600px) {
    }
  `};
`;

export const ImageCard = styled.div`
  ${() => css`
    height: 150px;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: ${({ src }) => `url(${src})`};
    @media (min-width: 600px) {
    }
  `};
`;

export const LockedLayer = styled.div`
  ${() => css`
    position: absolute;
    top: 0;
    left: 0;
    height: 150px;
    width: 100%;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    @media (min-width: 600px) {
    }
  `};
`;

export const ImageLocker = styled.div`
  ${() => css`
    height: 80px;
    width: 80px;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: ${({ src }) => `url(${src})`};
    @media (min-width: 600px) {
    }
  `};
`;

export const WrapperInfo = styled.div`
  ${({ isLocked }) => css`
    display: flex;
    flex-direction: column;
    padding: 24px;
    opacity: ${isLocked ? '0.3' : '1'};
    @media (min-width: 600px) {
    }
  `};
`;

export const WrapperInfoTime = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    span {
      color: ${theme.colors.primary};
      line-height: 14px;
      font-weight: 400;
    }
    span:nth-child(1) {
      text-transform: uppercase;
    }
    @media (min-width: 600px) {
    }
  `};
`;

export const Description = styled.div`
  ${({ theme }) => css`
    display: flex;
    font-family: ${theme.font.family.montserrat};
    color: #0e0d0d;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    @media (min-width: 600px) {
    }
  `};
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    margin-bottom: 15px;
    font-family: ${theme.font.family.montserrat};
    color: #000;
    font-weight: 600;
    @media (min-width: 600px) {
      font-size: 20px;
      line-height: 24px;
    }
  `};
`;

export const Subtitle = styled.h4`
  ${({ theme }) => css`
    line-height: 19px;
    font-size: 15px;
    margin-bottom: 18px;
    font-family: ${theme.font.family.montserrat};
    font-weight: 500;
    color: #333;
    @media (min-width: 600px) {
      font-size: 22px;
      line-height: 20px;
    }
  `};
`;
