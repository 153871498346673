import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import HtmlParser from 'html-react-parser';
import {
  Modal,
  ContainerDetails,
  Img,
  Disclaimer,
  Title,
  SubTitle,
  CloseButton,
  WrapperImg,
  ListItem,
} from 'pages/Wholesale/ModalProductDetails/styles';
import Bowser from 'bowser';
import get from 'lodash/get';

const ModalProductDetails = ({ isOpen, handleClose, product }) => {
  const { title, productImg, details = {} } = product;
  const { disclaimer, subTitle, items } = details;
  const isSafari =
    get(Bowser.parse(window.navigator.userAgent), 'browser.name') === 'Safari';

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={isOpen}>
        <ContainerDetails>
          <CloseButton onClick={handleClose}>X</CloseButton>
          <WrapperImg isSafari={isSafari}>
            <Img src={productImg} />
          </WrapperImg>
          <Title>{title}</Title>
          <SubTitle>{subTitle}</SubTitle>
          {items &&
            items.map(text => (
              <ListItem key={text}>{HtmlParser(text)}</ListItem>
            ))}
          <Disclaimer>{disclaimer}</Disclaimer>
        </ContainerDetails>
      </Fade>
    </Modal>
  );
};

export default ModalProductDetails;
