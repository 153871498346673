import React, { useState } from 'react';
import { PageContent } from 'ui/layouts';
import {
  Container,
  Title,
  Subtitle,
  SectionInput,
  WrapperInput,
  Button,
  SectionLink,
  TestLinkButton,
  WrapperTestButton,
  IconOpenLink,
  SectionErrorLink,
  SectionBottom,
  ButtonSave,
  ButtonCancel,
} from 'pages/WellnessAdvocateConfirmation/styles';
import Text from 'ui/Text';
import InputText from 'ui/InputText';
import StatusProgress from 'ui/StatusProgress';
import WelcomeHeader from 'ui/WelcomeHeader';
import ErrorMessageConfirmAccount from 'ui/ErrorMessageConfirmationAccount';
import useWellAdvocate from 'pages/WellnessAdvocateConfirmation/data/useWellAdvocate';
import useReactRouter from 'use-react-router';

const STATIC_STATUS = {
  EXPLORE: { itemComplete: true },
  TRY: { itemComplete: true },
  PREPARE: { itemComplete: true },
  AUTOMATE: { itemPending: true },
};

const WellnessAdvocateConfirmation = () => {
  const {
    getBackofficeLink,
    memberId,
    handleUpdateMemberId,
    link,
    handleUpdatePositionStatus,
  } = useWellAdvocate();

  const { history } = useReactRouter();

  const [isLinkTested, setIsLinkTested] = useState(false);

  const handleBackToTheFlow = () => history.push('/the-flow');

  const handleGenerateLink = async () => {
    await getBackofficeLink();
  };

  const handleVerifyLink = async () => {
    await handleUpdatePositionStatus();
    handleBackToTheFlow();
  };

  const handleClickLink = () => {
    setIsLinkTested(true);
  };

  return (
    <Container>
      <WelcomeHeader />
      <StatusProgress statusBar={STATIC_STATUS} />
      <PageContent>
        <Title>Confirm Wellness Advocate Status</Title>
        <Subtitle>Prepare to build your booming dōTERRA business!</Subtitle>
        <Text>
          To start automating your online business, we need your dōTERRA ID. To
          make sure the ID you give us is correct, we need to perform a quick
          test.
        </Text>
        <Text>
          <strong>Step 1</strong> - Please provide your dōTERRA ID and click
          "Generate Link."
        </Text>
        <SectionInput>
          <WrapperInput>
            <label>doTERRA ID</label>
            <InputText value={memberId} onChange={handleUpdateMemberId} />
          </WrapperInput>
          <Button
            disabled={!memberId}
            isDisabled={!memberId}
            onClick={handleGenerateLink}>
            Generate Link
          </Button>
        </SectionInput>

        {link && (
          <React.Fragment>
            <Text>
              <strong>Step 2</strong> - Click below to test the link. The link
              should open your personal enrollment page on a new tab or window.
            </Text>
            <Text>
              <span style={{ color: 'red' }}>
                Simply close the new tab or new window to return to this screen
                and continue the process.
              </span>
            </Text>
          </React.Fragment>
        )}

        <SectionLink isVisible={link}>
          <p>{link}</p>
          <WrapperTestButton>
            <TestLinkButton
              onClick={handleClickLink}
              href={link}
              target="_blank"
              rel="noopener noreferrer">
              Test Link <IconOpenLink />
            </TestLinkButton>
          </WrapperTestButton>
        </SectionLink>

        {isLinkTested && (
          <React.Fragment>
            <Text>
              <strong>Step 3</strong> - If the link is correct, click "Link
              Verified."
            </Text>
            <SectionErrorLink isVisible>
              <ErrorMessageConfirmAccount />
            </SectionErrorLink>
          </React.Fragment>
        )}

        <SectionBottom>
          <ButtonCancel onClick={handleBackToTheFlow}>Cancel</ButtonCancel>
          <ButtonSave
            isDisabled={!isLinkTested}
            disabled={!isLinkTested}
            onClick={handleVerifyLink}>
            Link Verified
          </ButtonSave>
        </SectionBottom>
      </PageContent>
    </Container>
  );
};

export default WellnessAdvocateConfirmation;
