import styled, { css } from 'styled-components';
import { Col } from 'ui/layouts';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @media (min-width: 600px) {
  }
`;

export const SectionTop = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  @media (min-width: 600px) {
    flex-direction: row;
  }
`;

export const WrapperTitle = styled.div`
  flex: 1;
  margin-bottom: 40px;
  @media (min-width: 600px) {
    margin-bottom: 0;
  }
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: 26px;
    line-height: 25px;
    font-weight: 800;
    color: ${theme.colors.primary};
    margin-bottom: 10px;
    @media (min-width: 600px) {
      padding: 0;
      font-size: 51px;
      line-height: 48px;
    }
  `}
`;

export const SectiontMain = styled(Col)`
  flex: 1;
  padding-bottom: 10px;
  @media (min-width: 600px) {
    flex: 3;
    padding-right: 20px;
    padding-bottom: 0;
  }
`;

export const Subtitle = styled.h4`
  ${({ theme }) => css`
    font-family: ${theme.font.family.visby};
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 20px;
    font-weight: 700;
    @media (min-width: 600px) {
      font-size: ${theme.font.size.subTitle};
    }
  `}
`;

export const TitleMiddle = styled.h4`
  ${({ theme }) => css`
    font-family: ${theme.font.family.montserrat};
    font-size: ${theme.font.size.subTitle};
    line-height: ${theme.font.lineHeight.subTitle};
    color: ${theme.colors.primary};
    margin-bottom: 10px;
    @media (min-width: 600px) {
      font-weight: 700;
    }
  `}
`;

export const HighlightText = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
    font-size: 20px;
    line-height: 25px;
    letter-spacing: -1px;
    font-weight: 800;
    border-left: 8px solid rgba(0, 0, 0, 0.15);
    padding-left: 20px;
    @media (min-width: 600px) {
      font-size: 26px;
      line-height: 29px;
    }
  `}
`;
