import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { WrapperVideo } from 'pages/TheFlow/styles';
import Text from 'ui/Text';
import {
  Container,
  SectionTop,
  SectiontMain,
  WrapperTitle,
  Title,
  Subtitle,
  TitleMiddle,
  HighlightText,
} from 'pages/TheFlow/Presentation/styles';

const Presentation = ({ pageContent }) => {
  const { header, body, highlight } = pageContent;
  const addLineBreaks = (string = '') =>
    string.split('\n').map((text, index) => (
      <React.Fragment key={`${text}-${index}`}>
        {text}
        <br />
      </React.Fragment>
    ));

  const addText = (string = '') =>
    string
      .split('\n')
      .map((text, index) => <Text key={`${text}-${index}`}>{text}</Text>);

  const addTitleMiddle = (string = '') =>
    string.split('\n').map((text, index) => (
      <TitleMiddle key={`${text}-${index}`}>
        {text}
        <br />
      </TitleMiddle>
    ));
  return (
    <Container>
      <SectionTop>
        <WrapperTitle>
          <Title>{addLineBreaks(header.title)}</Title>
          <Subtitle>{addLineBreaks(header.subtitle)}</Subtitle>
          {addText(header.text)}
        </WrapperTitle>
        {header.videoUrl && (
          <WrapperVideo>
            <ReactPlayer height={250} width="81%" url={header.videoUrl} />
          </WrapperVideo>
        )}
      </SectionTop>
      <SectiontMain>
        {addTitleMiddle(body.title)}
        {addText(body.text)}
        {highlight && <HighlightText>{addLineBreaks(highlight)}</HighlightText>}
      </SectiontMain>
    </Container>
  );
};

Presentation.propTypes = {
  pageContent: PropTypes.object,
};

export default Presentation;
