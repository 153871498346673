import React, { useState, useEffect } from 'react';
import useReactRouter from 'use-react-router';
import Player from 'ui/Player';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';
import useModule from 'pages/TheLab/data/useModule';
import Loading from 'ui/Loader';
import get from 'lodash/get';
import {
  ContainerVideoView,
  SectionTitle,
  Title,
  SectionVideo,
  VideoList,
  VideoItem,
  VideoItemThumb,
  VideoItemDescription,
  VideoCategory,
  VideoDisplay,
  ButtonTheFlow,
  WrapperVideo,
  WrapperVideoDescription,
  WrapperVideoTitle,
  WrapperPlayer,
  SectionAttachment,
  WrapperAttachment,
  Attachment,
  File,
  WrapperIcon,
  Description,
  MobileSupportFiles,
  SectionAttachmentMobile,
  WrapperDescription,
} from 'pages/VideoView/styles';

import file from 'assets/img/thelab/file.png';

const defaultGoBack = {
  path: '/the-lab',
  label: 'Go Back',
};

const VideoView = () => {
  const { history, location, match } = useReactRouter();
  const { data, fetchModule } = useModule();
  const [currentLesson, setCurrentLesson] = useState(false);
  const [goBackTo, setGoBackTo] = useState(defaultGoBack);
  const [isVisible, setIsVisible] = useState(false);
  const [isSelected, setIsSelected] = useState('');
  const [refList, setRefLest] = useState([]);

  const handleGoBack = () => history.push(goBackTo.path);

  const scrollToSelectedItem = React.useCallback(
    id => {
      if (refList[id]) {
        refList[id].current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    },
    [refList]
  );

  useEffect(() => {
    scrollToSelectedItem(isSelected);
  }, [isSelected, scrollToSelectedItem]);

  useEffect(() => {
    let moduleId = '';
    if (match.params.moduleId) {
      moduleId = match.params.moduleId;
    } else {
      moduleId = location.state.moduleId;
    }
    if (moduleId) {
      return fetchModule({ variables: { id: moduleId } });
    }
    history.push('/the-flow');
  }, []); // eslint-disable-line

  useEffect(() => {
    if (data?.labCourses) {
      const refs = [];
      data.labCourses.map(element => {
        element.labLessons.map(l => {
          refs[l.id] = React.createRef();
        });
      });
      setRefLest(refs);
    }
  }, [data]);

  useEffect(() => {
    if (match.params.lessonId && data) {
      let lesson = null;
      data.labCourses.forEach(element => {
        element.labLessons.filter(l => {
          if (l.id === match.params.lessonId) {
            lesson = l;
            return l;
          }
        });
      });
      if (lesson) {
        setCurrentLesson(lesson);
        setIsSelected(lesson.id);
      }
    } else {
      const firstLesson = get(data, 'labCourses[0].labLessons[0]');
      if (firstLesson) {
        setCurrentLesson(firstLesson);
        setIsSelected(firstLesson.id);
      }
    }
  }, [data, match.params.lessonId]);  // eslint-disable-line

  const handleLessonSelected = item => {
    setIsSelected(item.id);
    setCurrentLesson(item);
  };

  // 02dfe963-c4e6-431a-a1fa-44be77e102c6 - The Uncompromising Quality of dōTERRA’s Oils

  useEffect(() => {
    if (location.state?.backToTheFlow) {
      setGoBackTo({ path: '/the-flow', label: 'Go Back' });
    }
  }, []); // eslint-disable-line

  return data ? (
    <ContainerVideoView>
      <SectionTitle>
        <Title>{data.name}</Title>
        <ButtonTheFlow onClick={handleGoBack}>
          <ArrowLeftIcon size="small" /> <span>{goBackTo.label}</span>
        </ButtonTheFlow>
      </SectionTitle>
      <SectionVideo>
        <VideoList>
          {data.labCourses.map(({ id, name, labLessons }) => (
            <div key={id}>
              <VideoCategory>{name}</VideoCategory>
              {labLessons.map(item => (
                <VideoItem
                  key={item.video.id}
                  ref={refList[item.id]}
                  isSelected={isSelected === item.id}
                  onClick={() => handleLessonSelected(item)}>
                  <VideoItemThumb src={item.video.imagePath} />
                  <VideoItemDescription>{item.name}</VideoItemDescription>
                </VideoItem>
              ))}
            </div>
          ))}
        </VideoList>
        <VideoDisplay>
          {currentLesson ? (
            <WrapperVideo>
              <WrapperPlayer>
                <Player url={currentLesson.video.videoUrl} />
              </WrapperPlayer>
              <WrapperVideoDescription>
                <WrapperVideoTitle>
                  <h3>{currentLesson.name}</h3>
                  <span>
                    {Math.round(currentLesson.video.duration / 60)} min
                  </span>
                </WrapperVideoTitle>
                <WrapperDescription>
                  <Description
                    isVisible={isVisible}
                    dangerouslySetInnerHTML={{
                      __html: currentLesson.description,
                    }}></Description>
                  <span onClick={() => setIsVisible(prev => !prev)}>
                    {isVisible ? 'Hide Description' : 'Show More'}
                  </span>
                </WrapperDescription>
                <MobileSupportFiles
                  isHidden={currentLesson && currentLesson.attachments.length}>
                  {currentLesson && currentLesson.attachments.length ? (
                    <SectionAttachmentMobile href="#support-section">
                      <strong>
                        {`Supporting Files (${currentLesson.attachments.length})`}
                      </strong>
                      <WrapperIcon>
                        <ArrowDownwardRoundedIcon size="small" />
                      </WrapperIcon>
                    </SectionAttachmentMobile>
                  ) : null}
                </MobileSupportFiles>
              </WrapperVideoDescription>
            </WrapperVideo>
          ) : null}
        </VideoDisplay>
      </SectionVideo>
      {currentLesson && currentLesson.attachments.length ? (
        <SectionAttachment id="support-section">
          <h3>Supporting Files</h3>
          <WrapperAttachment>
            {currentLesson.attachments.map(attachment => (
              <Attachment
                key={attachment.name}
                href={attachment.url}
                target="_blank"
                rel="noopener noreferrer">
                <WrapperIcon>
                  <ArrowDownwardRoundedIcon size="small" />
                </WrapperIcon>
                <File src={file} />
                <span>{attachment.label}</span>
              </Attachment>
            ))}
          </WrapperAttachment>
        </SectionAttachment>
      ) : null}
    </ContainerVideoView>
  ) : (
    <ContainerVideoView>
      <Loading />
    </ContainerVideoView>
  );
};

export default VideoView;
