import styled, { css } from 'styled-components';
import ButtonDefault from 'ui/Button/Default';

export const Container = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    @media (min-width: 600px) {
    }
  `};
`;

export const Left = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 1;
    order: 1;
    @media (min-width: 600px) {
      }
    }
  `};
`;

export const Right = styled.div`
  ${() => css`
    order: 2;
    display: flex;
    flex-direction: column;
    flex: 1;
    input {
      margin-top: 15px;
    }
    @media (min-width: 600px) {
    }
    }
  `};
`;

export const WrapperCrop = styled.div`
  ${() => css`
    position: relative;
    width: 200px;
    height: 200px;
    margin-bottom: 30px;
    @media (min-width: 600px) {
      }
    }
  `};
`;

export const WrapperSlider = styled.div`
  ${() => css`
    display: flex;
    position: absolute;
    bottom: 0;
    width: 200px;
    @media (min-width: 600px) {
      }
    }
  `};
`;

export const Button = styled(ButtonDefault)`
  ${() => css`
    padding: 10px;
    font-size: 14px;
    width: 100%;
    text-transform: uppercase;
    margin-top: 20px;
    @media (min-width: 600px) {
      font-size: 16px;
    }
  `};
`;

export const SectionHeader = styled.div`
  ${() => css`
    padding: 15px 10px;
    text-align: center;
    position: relative;
    span {
      position: absolute;
      right: 25px;
      top: 24px;
      cursor: pointer;
      font-weight: 800;
    }
    @media (min-width: 600px) {
    }
  `};
`;
