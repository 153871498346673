import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import RouteWithLayout from 'routes/RouteWithLayout';
import MinimalLayout from 'ui/layouts/Minimal';
import HomeLayout from 'ui/layouts/Home';

import {
  Home,
  Wholesale,
  TheFlow,
  LoyaltyRewardsProgram,
  Play,
  CreateAccount,
  VideoView,
  TheLab,
  WellnessAdvocateUpdate,
  BecomeVIP,
  Profile,
  LoginCheck,
  SubscriptionCheck,
  NotFound,
  SetupEmail,
} from 'pages';

const Routes = () => (
  <Switch>
    <RouteWithLayout component={Home} layout={HomeLayout} exact path="/" />
    <RouteWithLayout component={SetupEmail} layout={HomeLayout} exact path="/error-email" />
    <RouteWithLayout
      component={Play}
      layout={MinimalLayout}
      exact
      path="/play"
    />
    <RouteWithLayout
      component={CreateAccount}
      layout={MinimalLayout}
      exact
      path="/create-account"
    />
    <RouteWithLayout
      component={Profile}
      layout={MinimalLayout}
      exact
      path="/your-account"
    />
    <RouteWithLayout
      component={BecomeVIP}
      layout={MinimalLayout}
      exact
      path="/become-vip"
    />
    <RouteWithLayout
      component={WellnessAdvocateUpdate}
      layout={MinimalLayout}
      exact
      path="/confirm-wellness-advocate-status"
    />
    <RouteWithLayout
      component={Wholesale}
      layout={MinimalLayout}
      exact
      path="/wholesale-account"
    />
    <RouteWithLayout
      component={VideoView}
      layout={MinimalLayout}
      exact
      isPrivate
      path="/video"
    />
        <RouteWithLayout
      component={VideoView}
      layout={MinimalLayout}
      exact
      isPrivate
      path="/video/:moduleId"
    />
    <RouteWithLayout
      component={VideoView}
      layout={MinimalLayout}
      exact
      isPrivate
      path="/video/:moduleId/:lessonId"
    />
    <RouteWithLayout
      component={TheLab}
      layout={MinimalLayout}
      exact
      isPrivate
      path="/the-lab"
    />
    <RouteWithLayout
      component={TheFlow}
      layout={MinimalLayout}
      exact
      isPrivate
      path="/the-flow"
    />
    <RouteWithLayout
      component={LoyaltyRewardsProgram}
      layout={MinimalLayout}
      exact
      path="/lrp"
    />
    <Route path="/login-check" component={LoginCheck} />
    <Route path="/subscription-check" component={SubscriptionCheck} />
    <RouteWithLayout
      component={NotFound}
      exact
      layout={MinimalLayout}
      path="/not-found"
    />
    <Redirect to="/not-found" />
  </Switch>
);

export default Routes;
