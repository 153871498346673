import React from 'react';
import useTheLab from 'pages/TheLab/data/useTheLab';
import { PageContent } from 'ui/layouts';
import GoBackToTheFlowPage from 'ui/GoBackToTheFlowPage';
import { SectionHeader, Title, Subtitle } from 'pages/TheLab/styles';
import Courses from 'pages/TheLab/Courses';

const TheLab = () => {
  const { data } = useTheLab();

  return (
    <PageContent>
      <GoBackToTheFlowPage />
      <SectionHeader>
        <Title>The Lab</Title>
        <Subtitle>
          Where you learn all the cool stuff in one place undistracted.
        </Subtitle>
        <p>
          Can you really make money with dōTERRA? Why dōTERRA? How do you build
          a dōTERRA business online? What’s the deal with essential oils? Start
          exploring! With Drippl, you learn everything you need to know on your
          own time and at your own pace.
        </p>
      </SectionHeader>
      <Courses courses={data} />
    </PageContent>
  );
};

export default TheLab;
