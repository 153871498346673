import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { GET_MODULES } from 'graphql/queries/getModules';
import get from 'lodash/get';
import { POSITION_STATUS } from 'config/constants';

const useTheLab = () => {
  const [modules, setModules] = useState([]);
  const { loading, error, data } = useQuery(GET_MODULES);

  const { CUSTOMER, PENDING_BUILDER, BUILDER } = POSITION_STATUS;

  const MOD2_ALLOWED = [CUSTOMER, PENDING_BUILDER, BUILDER];
  const MOD3_ALLOWED = [BUILDER];

  useEffect(() => {
    if (data) {
      const res = get(data, 'labModules', []);
      const positionStatus = get(data, 'self.positionStatus', '');

      const modulesWithCount = res.map(module => {
        let isLocked = false;
        let lockedErrorMessage = '';

        const countLessons = module.labCourses.reduce((acc, curr) => {
          const count = curr.labLessons.length;
          return acc + count;
        }, 0);
        const { name } = module;

        const module2Regex = new RegExp(/Module 2:/gi);
        const module3Regex = new RegExp(/Module 3:/gi);

        if (module2Regex.test(name) && !MOD2_ALLOWED.includes(positionStatus)) {
          isLocked = true;
          lockedErrorMessage = 'Requires dōTERRA Wholesale Customer membership';
        }

        if (module3Regex.test(name) && !MOD3_ALLOWED.includes(positionStatus)) {
          isLocked = true;
          lockedErrorMessage = 'Requies dōTERRA Welllness Advocate account';
        }

        return {
          ...module,
          countLessons,
          isLocked,
          lockedErrorMessage,
        };
      });
      setModules(modulesWithCount);
    }
  }, [data]); // eslint-disable-line

  return {
    loading,
    error,
    data: modules,
  };
};

export default useTheLab;
