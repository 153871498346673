import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'ui/Modal';
import useTheFlow from 'pages/TheFlow/data/useTheFlow';
import {
  Container,
  SectionHeader,
  SectionMemberOptions,
  WrapperInput,
} from 'pages/TheFlow/Steps/UpdateMembershipModal/styles';

import { POSITION_STATUS, MEMBER_STATUS } from 'config/constants';

const UpdateMembershipModal = ({ isOpen, handleClose }) => {
  const { CUSTOMER, PENDING_BUILDER, EXPLORING } = POSITION_STATUS;
  const { MEMBER, PREMEMBER } = MEMBER_STATUS;
  const { handleUpdateMemberStatuses } = useTheFlow();

  const [option, setOption] = useState('');

  const onChange = e => setOption(e.target.value);

  const onSubmit = () => {
    const oweStatus = option === EXPLORING ? PREMEMBER : MEMBER;
    handleUpdateMemberStatuses({ positionStatus: option, oweStatus });
    handleClose();
  };

  return (
    <Modal
      openAfterSubmit={false}
      isOpen={isOpen}
      onClose={handleClose}
      handleClose={handleClose}
      onSubmit={onSubmit}
      isDisableSubmit={!option}
      submitLabel={'Save'}>
      <Container>
        <SectionHeader>
          <h2>Welcome! Please take a moment to update your dōTERRA status.</h2>
          <p>
            We'll use this information to make sure you stay on the right track!
          </p>
        </SectionHeader>
        <SectionMemberOptions>
          <WrapperInput>
            <input
              id="member"
              onChange={onChange}
              value={CUSTOMER}
              type="radio"
              checked={option === CUSTOMER}
            />
            <label htmlFor="member">
              <strong>Wholesale Customer</strong> - My dōTERRA account allows me
              to purchase products at a discount, but I cannot sell products or
              earn commissions.
            </label>
          </WrapperInput>

          <WrapperInput>
            <input
              id="new"
              onChange={onChange}
              value={PENDING_BUILDER}
              type="radio"
              checked={option === PENDING_BUILDER}
            />
            <label htmlFor="new">
              <strong>Wellness Advocate</strong> - I can sell dōTERRA products
              and earn commissions
            </label>
          </WrapperInput>
          <WrapperInput>
            <input
              id="still"
              onChange={onChange}
              value={EXPLORING}
              type="radio"
              checked={option === EXPLORING}
            />
            <label htmlFor="still">
              <strong>Still Exploring</strong> (Take me back!) - I have yet to
              join dōTERRA
            </label>
          </WrapperInput>
        </SectionMemberOptions>
      </Container>
    </Modal>
  );
};

export default UpdateMembershipModal;

UpdateMembershipModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
};
