import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { ApolloLink } from 'apollo-link';
import { withClientState } from 'apollo-link-state';
import { defaults, resolvers, typeDefs } from 'clientState';
import { onError } from 'apollo-link-error';
import { useAuth0 } from 'context/auth';
import get from 'lodash/get';

const useApolloClientConfig = () => {
  const { token, logout } = useAuth0();
  const cache = new InMemoryCache({});

  const clientState = withClientState({
    typeDefs,
    defaults,
    resolvers,
    cache,
  });

  const httpLink = new HttpLink({
    // uri: 'https://staging.api.drippl.com/graphql',
    // uri: 'https://api.drippl.com/graphql',
    // uri: 'http://localhost:1337/graphql',
    uri: `${process.env.REACT_APP_API_HOST}/graphql`,
  });

  const headerLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  }));

  const client = new ApolloClient({
    typeDefs,
    link: ApolloLink.from([
      clientState,
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.map(({ message, locations, path, extensions }) => {
            const codeError = get(extensions, 'exception.output.statusCode');
            console.log(
              `Message: ${message}, Location: ${
                locations ? JSON.stringify(locations) : ''
              }, Path: ${path} , Code: ${codeError}`
            );
            if (
              message ===
              'Your account has not been confirmed by the administrator.'
            ) {
              window.location.assign(`https://www.drippl.com/coming-soon`);
            } else if (codeError === 401) {
              logout();
              window.location.assign(window.location.origin);
            }
          });
        }
        if (networkError) {
          console.log('networkError: ', networkError);
        }
      }),
      headerLink,
      httpLink,
    ]),
    cache,
  });

  return { client };
};

export default useApolloClientConfig;
