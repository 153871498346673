import styled, { css } from 'styled-components';
import { Col, Row } from 'ui/layouts';
import LinkButton from 'ui/Button/LinkButtonDefault';

export const ContactButton = styled.button`
  font-size: 14px;
  cursor: pointer;
  width: 210px;
  height: 40px;
  border: solid 1px #e4e4e4;
  border-radius: 2px;
  background-color: #fff;
  transform: translate(0px, 0px);
  transition: all 200ms ease;
  color: #585858;
  font-weight: 300;
  text-align: center;
  &:hover {
    background-color: #f0f0f0;
    transform: translate(0px, -3px);
    transition: all 200ms ease;
  }
`;

export const ChooseRow = styled(Row)`
  justify-content: center;
  margin-bottom: 40px;
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
    margin-top: 30px;
  }
`;
export const MiddleText = styled.span`
  align-self: center;
  margin: 30px 0;
  font-family: Montserrat, sans-serif;
  color: #0e0d0d;
  font-size: 17px;
  line-height: 27px;
  font-weight: 300;
  text-align: center;

  @media (min-width: 600px) {
    margin: 35px 0 45px;
  }
`;

export const BoldText = styled.span`
  color: black;
  font-weight: bold;
`;

export const Subtitle = styled.h3`
  margin-bottom: 20px;
  line-height: 30px;
  font-weight: 500;
  color: #5ed6d5;
  font-size: 23px;
`;

export const ChooseBox = styled.div`
  position: relative;
  display: flex;
  width: 30%;
  margin-right: 27px;
  margin-left: 27px;
  padding: 50px 27px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: solid 1px #d1d1d1;
  border-radius: 14px;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const ChooseNumber = styled.div`
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  display: flex;
  width: 50px;
  height: 50px;
  margin-top: -24px;
  margin-right: auto;
  margin-left: auto;
  flex-direction: column;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  border-radius: 200px;
  background-color: #00d1cf;
  color: #fff;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  font-weight: bold;
`;

export const ChooseText = styled.p`
  margin-bottom: auto;
  color: #0e0d0d;
  font-size: 17px;
  line-height: 27px;
  font-weight: 300;
  text-align: center;
`;

export const Text = styled.p`
  margin-bottom: 19px;
  color: ${({ color }) => (color ? `${color}` : '#0e0d0d')};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '16px')};
  line-height: 25px;
  font-weight: 500;
  text-align: ${({ center }) => (center ? 'center' : 'left')};
`;

export const ProductBox = styled(Row)`
  justify-content: center;
  margin-top: 30px;
  flex-wrap: wrap;
  @media (max-width: 600px) {
    padding: 0;
    flex-direction: column;
  }
`;

export const Product = styled(Col)`
  padding: 0 20px 10px;
  border-radius: 8px;
  border: 1px solid rgba(224, 224, 224, 0.8);
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 35px;
  margin-right: 20px;
  @media (max-width: 600px) {
    width: 100%;
    margin-bottom: 40px;
    margin-right: 0;
  }
`;

export const ProductLabel = styled.div`
  background-color: #f3cb64;
  font-size: 15px;
  line-height: 30px;
  border-radius: 200px;
  color: white;
  font-weight: 600;
  text-transform: capitalize;
  padding: 1px 18px;
  transform: translateY(-50%);
`;

export const ProductImage = styled(Row)`
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 2px;
  border: none;
  height: 139px;
  width: 218px;
  background-image: ${({ src }) => `url(${src})`};
  margin-top: ${({ hasMarginTop }) => (hasMarginTop ? '32px' : null)};
`;

export const PriceBox = styled(Row)`
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
`;

export const Price = styled.span`
  font-size: 20px;
  text-decoration: line-through;
`;

export const PriceDescount = styled.span`
  padding-left: 10px;
  font-size: 45px;
  font-weight: 700;
`;

export const ProductDescription = styled.span`
  font-size: 20px;
  margin-top: 20px;
`;

export const ProductPrice = styled.div``;

export const ProductPriceDescount = styled.span`
  font-size: 14px;
  color: #0e0d0d;
  margin-top: 15px;
`;

export const ProductButton = styled(LinkButton)`
  margin: 15px 0px;
  font-size: 19px;
  text-transform: uppercase;
  width: 100%;
  height: 52px;
  text-align: center;
  align-self: center;
  line-height: 52px;
  font-weight: 500;
`;

export const EnrollSection = styled(Col)`
  p {
    padding: 0 10%;
  }
  button {
    width: 60%;
  }
  > div {
    display: block;
    margin: 15px 0;
  }
`;

export const StepSection = styled(Col)`
  margin: 40px 0px 30px 0px;
`;

export const StepRow = styled(Row)`
  justify-content: space-between;
  margin: 30px 0 20px;
  @media (max-width: 600px) {
    padding: 0;
    flex-direction: column;
  }
`;

export const StepBox = styled(ChooseBox)`
  width: 23%;
  margin: 0px;
  @media (max-width: 600px) {
    width: 100%;
    margin-bottom: 25px;
  }
`;

export const SeeDetails = styled(Text)`
  ${() => css`
    cursor: pointer;
    padding: 0;
    margin: 0;
    margin-top: 15px;
    color: #2d65d1;
    text-decoration: underline;
    @media (min-width: 600px) {
    }
  `};
`;

export const WrapperVideo = styled.div`
  ${() => css`
    padding: 0;
    height: 200px;
    flex: 1;
    margin-bottom: 20px;
    @media (min-width: 600px) {
      height: 350px;
      padding: 0 15%;
      margin-bottom: 40px;
    }
  `};
`;

export const WarningBox = styled.div`
  margin: 15px;
  align-self: center;
  color: red;
  font-size: 16px;
  line-height: 28px;
  span {
    text-decoration: none;
    cursor: pointer;
    color: blue;
  }
`;
