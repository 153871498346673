import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { GET_MEMBER_STATUS } from 'graphql/queries/getMemberStatus';
import get from 'lodash/get';
import kitList from 'pages/Wholesale/data/kits';
import useReactRouter from 'use-react-router';

const DEFAULT_LINK = 'https://www.doterra.com/US/en/pl/enrollment-kits';

const useWhosale = () => {
  const { location } = useReactRouter();

  const [setupLink, setSetupLink] = useState(DEFAULT_LINK);
  const [productList, setProductList] = useState([]);

  const { data } = useQuery(GET_MEMBER_STATUS);

  useEffect(() => {
    const referralMemberId = get(
      data,
      'self.referredBy.doterraAccount.memberId'
    );
    const tempMemberId = get(location, 'state.memberId') || '';
    const DOTERRA_ID = referralMemberId || tempMemberId;

    const list = kitList.map(product => {
      const regex = /doTERRAID/gi;
      let productUrl;
      if (DOTERRA_ID) {
        productUrl = product.productUrl.replace(regex, DOTERRA_ID);
      } else {
        productUrl = DEFAULT_LINK;
      }
      const productID = product.productUrl.match(
        new RegExp('WC&' + '(.*)' + '=')
      );
      return {
        ...product,
        productUrl,
        productID: productID[1],
      };
    });
    setProductList(list);
  }, [data, location, location.state]);

  useEffect(() => {
    if (data) {
      const link = get(
        data,
        '.self.referredBy.doterraAccount.backOfficeLink',
        DEFAULT_LINK
      );
      setSetupLink(link);
    }
  }, [data]);

  return {
    data,
    setupLink,
    productList,
  };
};

export default useWhosale;
