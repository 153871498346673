import styled, { css } from 'styled-components';

export const Container = styled.div`
  height: 64px;
  background-color: white;
  display: ${({ showContact }) => (showContact ? 'flex' : 'none')};
  justify-content: flex-start;
  align-items: center;
  padding-left: 40px;
  margin-bottom: 20px;
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.2);
  @media (min-width: 600px) {
    margin-bottom: 30px;
  }
`;

export const Avatar = styled.div`
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${({ src }) => `url(${src})`};
  @media (min-width: 600px) {
  }
`;

export const Text = styled.div`
  ${({ theme }) => css`
    margin-left: 20px;
    font-size: 14px;
    span {
      color: ${theme.colors.primary};
    }
    @media (min-width: 600px) {
    }
  `}
`;

export const Button = styled.button`
  padding: 10px 20px;
  border: solid 1px #d1d1d1;
  border-radius: 4px;
  background-color: #fff;
  transition: all 200ms ease;
  font-family: Visbyroundcf, sans-serif;
  color: #414141;
  font-size: 14px;
  margin-left: 20px;
  &:hover {
    background-color: #eee;
    transform: translate(0px, -2px);
  }

  @media (min-width: 600px) {
  }
`;
