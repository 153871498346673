import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import SideMenu from 'ui/layouts/Minimal/Sidebar/SideMenu';
import { Drawer } from 'ui/layouts/Minimal/Sidebar/styles';
import { makeStyles } from '@material-ui/styles';
import TheFlowIcon from 'ui/icons/TheFlow';
import TheLabIcon from 'ui/icons/TheLab';
import UserAccountIcon from 'ui/icons/UserAccount';
import { MEMBER_STATUS } from 'config/constants';
import useTheFlow from 'pages/TheFlow/data/useTheFlow';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#00d1d0',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
}));

const Sidebar = props => {
  const { open, variant, onClose, className, ...rest } = props;
  const { oweStatus } = useTheFlow();
  const classes = useStyles();
  const shouldHidePages = oweStatus === MEMBER_STATUS.SETUP;

  const pagesLimited = [
    {
      title: 'The Flow',
      href: '/the-flow',
      icon: <TheFlowIcon />,
    },
  ];

  const pages = [
    {
      title: 'The Flow',
      href: '/the-flow',
      icon: <TheFlowIcon />,
    },
    {
      title: 'The Lab',
      href: '/the-lab',
      icon: <TheLabIcon />,
    },
    {
      title: 'Your Account',
      href: '/your-account',
      icon: <UserAccountIcon />,
    },
  ];

  return (
    <Drawer anchor="left" onClose={onClose} open={open} variant={variant}>
      <div {...rest} className={clsx(classes.root, className)}>
        <SideMenu
          className={classes.nav}
          pages={shouldHidePages ? pagesLimited : pages}
        />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default Sidebar;
