import React from 'react';
import Cards from 'pages/TheFlow/Steps/Cards';
import Setup from 'pages/TheFlow/Steps/Setup';
import { MEMBER_STATUS } from 'config/constants';
import Loader from 'ui/Loader';

const Steps = ({
  data,
  loading,
  handleUpdatePositionStatus,
  handleUpdateMemberStatus,
  videosInfo,
  referralId,
}) => {
  if (loading) return <Loader />;

  if (data) {
    const { oweStatus, positionStatus } = data.self;

    if (oweStatus === MEMBER_STATUS.SETUP) {
      return <Setup />;
    }

    return (
      <Cards
        doTerraStatus={positionStatus}
        oweStatus={oweStatus}
        handleUpdatePositionStatus={handleUpdatePositionStatus}
        handleUpdateMemberStatus={handleUpdateMemberStatus}
        videosInfo={videosInfo}
        referralId={referralId}
      />
    );
  }
  return <Loader />;
};

export default Steps;
