const PREMEMBER_STATUS = {
  EXPLORING: {
    EXPLORE: {
      itemComplete: true,
    },
    TRY: {
      itemPending: true,
    },
    PREPARE: {},
    AUTOMATE: {},
  },
  PENDING_CUSTOMER: {
    EXPLORE: {
      itemComplete: true,
    },
    TRY: {
      itemPending: true,
    },
    PREPARE: {},
    AUTOMATE: {},
  },
};

export const PREMEMBER_PAGE = {
  EXPLORING: {
    header: {
      title: 'Health. \n Wealth.\n Freedom.',
      subtitle: 'Is an online dōTERRA \n business right for you?',
      text: '',
      videoUrl: 'https://vimeo.com/428335698',
      stepStatus: PREMEMBER_STATUS.EXPLORING,
    },
    body: {
      title: '',
      text:
        'Can you really make money with dōTERRA? Why dōTERRA? How do you build a dōTERRA business online? What’s the deal with essential oils? Start exploring! With Drippl, you learn everything you need to know on your own time and at your own pace. \n Your journey to a healthier, wealthier you then starts when you put the products to the test. We encourage everybody to get started with a wholesale account which allows you to purchase dōTERRA products at a 25% discount. (No minimums. Cancel anytime. All that jazz.) Why? Experiencing the oils will fuel your passion to get them into everyone’s hands!',
    },
    highlight:
      'When you’re ready to rock ‘n roll with your \n own biz, we’ll walk you through next steps! \n And then the fun really begins! ;)',
  },
  PENDING_CUSTOMER: {
    header: {
      title: 'Are you in?',
      subtitle:
        'After you’ve set up an account with dōTERRA, the fun really starts.',
      text:
        'We just need to validate your current dōTERRA status so we can best support you. If you have your dōTERRA ID, go ahead and click “Confirm Membership” and let’s get going! ',
      videoUrl: 'https://vimeo.com/428335641',
      stepStatus: PREMEMBER_STATUS.PENDING_CUSTOMER,
    },
    body: {
      title: 'Wanna keep looking around?',
      text:
        'Of course, if you haven’t enrolled yet and would like some more time to explore, that’s 100% cool too! Just click below to return to the previous step where you can continue to explore at your leisure. When you’re ready to open your account and put the products to the test, follow the steps to return here and update your membership.',
    },
    highlight: '',
  },
  EMPTY: {
    header: {
      title: '',
      subtitle: '',
      text: '',
      videoUrl: '',
      stepStatus: PREMEMBER_STATUS.EXPLORING,
    },
    body: {
      title: '',
      text: '',
    },
    highlight: '',
  },
};
