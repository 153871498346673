import React from 'react';
import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_SELF } from 'graphql/queries/getSelf';
import Loading from 'ui/Loading';

import { useAuth0 } from 'context/auth';

const SubscriptionCheck = () => {
  //do an authcheck and then redirect to the flow
  const { isAuthenticated, loginWithRedirect, token } = useAuth0();
  let data;

  useEffect(() => {
    const checkSubscription = async () => {
      if (!isAuthenticated) {
        try {
          await loginWithRedirect({
            redirect_uri: `${window.location.origin}/subscription-check`,
          });
        } catch (e) {
          console.log('Login failed');
        }
      } else {
        // getSelf()
        // Call api to check subscription
        const axios = require('axios');
        const result = await axios({
          method: 'get',
          url: `${process.env.REACT_APP_API_HOST}/stripe-customer/check-vip-subscription`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        window.location.replace(`${window.location.origin}/the-flow`);
        return { data: result.data };

        //on response redirect to the flow
      }
    };
    data = checkSubscription();
  }, [isAuthenticated, loginWithRedirect]);

  if (!isAuthenticated) {
    return <Loading />;
  }

  return (
    <div>
      <Loading />;
    </div>
  );
};

export default SubscriptionCheck;
