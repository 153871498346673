const SETUP_STATUS = {
  EXPLORE: {
    itemComplete: true,
  },
  TRY: {
    itemPending: true,
  },
  PREPARE: {},
  AUTOMATE: {},
};

const CONTENT = {
  header: {
    title: 'Welcome!',
    subtitle:
      'And congratulations on taking another step towards a life of better health, more wealth, and freedom to live on your terms! \n\n With your FREE account, you can come back anytime you want, pick up where you left off, and explore at your own pace. \n\n To get started, let us know whether or not you are already a dōTERRA member.',
    text: '',
    videoUrl: '',
    stepStatus: SETUP_STATUS,
  },
  body: {
    title: '',
    text: '',
  },
  highlight: '',
};

export const SETUP_PAGE = {
  EXPLORING: CONTENT,
  PENDING_CUSTOMER: CONTENT,
  CUSTOMER: CONTENT,
  BUILDER: CONTENT,
  PENDING_BUILDER: CONTENT,
};
