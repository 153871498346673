import { useQuery } from '@apollo/react-hooks';
import { GET_MEMBER_REFER } from 'graphql/queries/getMemberRefer';
import get from 'lodash/get';

const useContactBar = () => {
  const { loading, error, data } = useQuery(GET_MEMBER_REFER);
  return {
    loading,
    error,
    data: get(data, 'self.referredBy') || {},
  };
};

export default useContactBar;
