import React, { useEffect, useState, Fragment } from 'react';
import { useForm } from 'react-hook-form';
import get from 'lodash/get';
import Input from 'ui/InputText';
import useProfile from 'pages/Profile/data/useProfile';

import { SectionForm, WrapperInput, Button } from 'pages/Profile/styles';
import {
  Link,
  WrapperInfo,
  WrapperBottom,
} from 'pages/Profile/DoTerraForm/styles';

const DoTerraForm = ({ data }) => {
  const { register, handleSubmit, errors, setValue } = useForm();
  const { handleUpdateMemberId } = useProfile();

  const [inputErrors, setInputErrors] = useState([]);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [referredByName, setReferredByName] = useState(' - ');
  const [rank, setRank] = useState(' - ');
  const [backOfficeLink, setBackOfficeLink] = useState('');

  const onSubmit = async formData => {
    setIsSaveDisabled(true);
    await handleUpdateMemberId(formData);
  };

  useEffect(() => {
    if (errors) {
      const fieldErros = Object.entries(errors).map(([key]) => key);
      setInputErrors(fieldErros);
    }
  }, [errors]);

  useEffect(() => {
    if (data) {
      const memberId = get(data, 'doterraAccount.memberId', '') || ' - ';
      const backOffLink = get(data, 'doterraAccount.backOfficeLink', '') || '';
      const rankName = get(data, 'rankGoal.currentRank.name', '') || ' - ';
      const referredName = get(data, 'referredBy.fullName', '') || ' - ';
      setReferredByName(referredName);
      setRank(rankName);
      setValue([{ memberId }]);
      setBackOfficeLink(backOffLink);
    }
  }, [backOfficeLink, data, register, setValue]);

  return (
    <Fragment>
      <SectionForm onSubmit={handleSubmit(onSubmit)}>
        <WrapperInput>
          <label>Referred By</label>
          <div>
            <Input
              disabled
              isDisabled
              name="referredByName"
              value={referredByName}
            />
          </div>
        </WrapperInput>

        <WrapperInput>
          <label>Rank</label>
          <div>
            <Input name="rank" disabled isDisabled value={rank} />
          </div>
        </WrapperInput>
        <WrapperInput>
          <label>doTerra ID</label>
          <div>
            <Input
              name="memberId"
              hasError={inputErrors.includes('memberId')}
              ref={register({ required: true })}
            />
          </div>
        </WrapperInput>
        <Button
          disabled={isSaveDisabled}
          isDisabled={isSaveDisabled}
          type="submit">
          {isSaveDisabled ? 'saved' : 'update'}
        </Button>
      </SectionForm>
      {backOfficeLink && (
        <WrapperBottom>
          <Link href={backOfficeLink} target="_blank" rel="noopener noreferrer">
            {backOfficeLink}
          </Link>
          <WrapperInfo>
            Click on the link above to verify that it directs to your back
            office sign-up page. If it does not take you to your back office
            page, and you are currently enrolled as a Wellness Advocate, make
            sure that you have entered your doTERRA ID correctly. Call doTERRA
            support if you need assistance verifying your doTERRA ID or your
            back office link:
            <span> 1 (800) 411-8151</span>
          </WrapperInfo>
        </WrapperBottom>
      )}
    </Fragment>
  );
};

export default DoTerraForm;
