const MEMBER_STATUS = {
  CUSTOMER: {
    EXPLORE: { itemComplete: true },
    TRY: { itemComplete: true },
    PREPARE: { itemPending: true },
    AUTOMATE: {},
  },
  PENDING_BUILDER: {
    EXPLORE: { itemComplete: true },
    TRY: { itemComplete: true },
    PREPARE: { itemComplete: true },
    AUTOMATE: { itemPending: true },
  },
  BUILDER: {
    EXPLORE: { itemComplete: true },
    TRY: { itemComplete: true },
    PREPARE: { itemComplete: true },
    AUTOMATE: { itemPending: true },
  },
  EXPLORING: {
    EXPLORE: { itemComplete: true },
    TRY: { itemPending: true },
    PREPARE: {},
    AUTOMATE: {},
  },
};

export const MEMBER_PAGE = {
  CUSTOMER: {
    header: {
      title: 'Lemon, Tea Tree\n and Frankincense…\n Oh my!',
      subtitle:
        'If you’re new to essential oils, it’s\n totally normal to feel a little\n overwhelmed.',
      text: '',
      videoUrl: 'https://vimeo.com/428336173',
      stepStatus: MEMBER_STATUS.CUSTOMER,
    },
    body: {
      title: '',
      text:
        ' No worries! We’ll help you discover easy and powerful ways to incorporate oils into your daily life. To make sure you always have your favorites on hand and to earn free products just for consistently ordering, you’ll want to set up your Loyalty Rewards Program (LRP) if you haven’t already. \n Gaining a passion for the products is only part of the fun! Wait till you start sharing and building a business completely centered around helping others discover the same empowering, positive, and health-centered lifestyle. When you’re ready, upgrade your account to Wellness Advocate and let’s get started. \n Need assistance upgrading your account? Contact dōTERRA support at 1-800-411-8151 ',
    },
    highlight: '',
  },
  PENDING_BUILDER: {
    header: {
      title: 'Are you ready to automate your business?',
      subtitle: '',
      text: '',
      videoUrl: 'https://vimeo.com/428335855',
      stepStatus: MEMBER_STATUS.PENDING_BUILDER,
    },
    body: {
      title: '',
      text:
        'In this step you will confirm your Wellness Advocate status by providing your dōTERRA ID and testing a link that Drippl will generate. Drippl uses this link to sell enrollment kits to your referrals and to make sure they enroll on your team. You received your ID when you first enrolled — it is the same number you use to sign in on dōTERRA’s site. \n If you’ve forgotten your dōTERRA ID, you can contact dōTERRA support at 1-800-411-8151',
    },
    highlight: '',
  },
  BUILDER: {
    header: {
      title: 'It’s game time!',
      subtitle:
        'Alright. Let’s get down to business... building your business!',
      text: '',
      videoUrl: 'https://vimeo.com/428336244',
      stepStatus: MEMBER_STATUS.BUILDER,
    },
    body: {
      title: '',
      text:
        'First, let’s lay your foundation. Learn how the business works, check out some pro-tips on organizing your team, and get ideas for successfully sharing in Module 3: “Get ready to build.” Remember, you can always access the courses again and again in The Lab! \n Then, activate your VIP Membership to unlock all the content and capabilities of Drippl! You’ll see your unique referral link below that you can use to invite, educate, and enroll others with dōTERRA. Anybody who follows your link and decides to open a dōTERRA account will be sent to your personal enrollment page. \n What are you waiting for? Let’s go!',
    },
    highlight: '',
  },
  EXPLORING: {
    header: {
      title: 'Ready to take your business online?',
      subtitle: 'Drippl makes it easy while saving you both time & money!',
      text: '',
      videoUrl: 'https://vimeo.com/428335996',
      stepStatus: MEMBER_STATUS.EXPLORING,
    },
    body: {
      title: '',
      text:
        'Let’s get real. It’s the 21st century – the age of online learning, telecommuting, and shopping from the couch in your PJs! With Drippl, it’s easier than ever to use the power of the internet and social media to invite, educate, and enroll others with dōTERRA. \n When you refer potential builders to Drippl, they are guided step by step through the process of learning about dōTERRA, purchasing enrollment kits from you, and building their own business as part of YOUR team! \n 1) Take a look at the The Lab – our online education platform – to get a feel for what your contacts will see when they come. \n 2) Confirm your membership so we can walk you through the rest!',
    },
    highlight: '',
  },
};
