import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useReactRouter from 'use-react-router';

import {
  ContainerCourses,
  CourseCard,
  WrapperImage,
  WrapperInfo,
  Title,
  Description,
  WrapperInfoTime,
  ImageCard,
  LockedLayer,
  ImageLocker,
  ErrorMessage,
} from 'pages/TheLab/Courses/styles';
import lockerImgSrc from 'assets/img/thelab/lockicon.png';

const Courses = ({ courses }) => {
  const { history } = useReactRouter();

  const handleOnClick = ({ id, name, isLocked }) => {
    if (isLocked) {
      return null;
    }
    history.push({
      pathname: '/video',
      state: { moduleId: id, moduleName: name },
    });
  };
  return (
    <ContainerCourses>
      {courses.map(
        ({
          id,
          name,
          labCourses,
          shortDescription,
          coverImage,
          countLessons,
          isLocked,
          lockedErrorMessage,
        }) => (
          <CourseCard
            key={id}
            onClick={() => handleOnClick({ name, id, isLocked })}
            isLocked={isLocked}>
            <WrapperImage>
              {isLocked && (
                <LockedLayer>
                  <ErrorMessage className="video-error">
                    {lockedErrorMessage}
                  </ErrorMessage>
                  <ImageLocker src={lockerImgSrc} className="locker-img" />
                </LockedLayer>
              )}
              <ImageCard src={coverImage?.url} />
            </WrapperImage>
            <WrapperInfo isLocked={isLocked}>
              <WrapperInfoTime>
                <span>
                  {labCourses.length} course{labCourses.length > 1 ? 's' : ''}
                </span>
                <span>
                  {countLessons} VIDEO{countLessons > 1 ? 'S' : ''}
                </span>
              </WrapperInfoTime>
              <Title>{name}</Title>
              <Description>{shortDescription}</Description>
            </WrapperInfo>
          </CourseCard>
        )
      )}
    </ContainerCourses>
  );
};

Courses.propTypes = {
  courses: PropTypes.array,
};
export default Courses;
