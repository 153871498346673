import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Icon(props) {
  return (
    <SvgIcon viewBox="0 0 360 360" className={props.className}>
      <path
        className="st0"
        d="M163.6,179.2c0,42.5,0,84.9,0,127.4c0,18.4-15.2,33.5-33.1,33.2c-14.6-0.3-29.8-14.6-30.2-29.3
			c-0.5-18.1-0.3-36.2-0.3-54.2c0-34.6,0-69.1,0-103.7c0-11.9,0.1-23.8,0-35.8c-0.1-12.8-9-19.6-21.4-16.5
			c-7.3,1.8-11.1,7.5-11.2,16.9c-0.1,15-0.5,30,0.1,45c0.7,17.4-15.6,34.4-33.6,33.6c-7.1-0.3-14.2,0-21.3-0.1
			c-5.3-0.1-8.8-3.3-8.8-7.9c0.1-4.5,3.7-7.8,8.9-7.9c7.6-0.1,15.3,0,22.9,0c10.3,0,16.2-5.9,16.2-16.2c0.1-15.7,0-31.3,0-47
			C52,99.9,65.5,84.7,82.3,84c15.4-0.7,28.8,10.7,32.1,24.8c1.2,5.1,1.1,10.6,1.1,16c0.1,49.4,0,98.9,0,148.3
			c0,11.4,0,22.8-0.1,34.2c-0.1,16.6,14.4,19.4,23.9,15.5c5.1-2.1,8-6.3,8.2-11.9c0.3-8,0.3-16.1,0.3-24.1c0-77.4,0-154.9,0-232.3
			c0-12.2,4.8-21.9,14.7-28.7c20.6-14.3,42.4,0.7,47.6,17.8c1,3.4,1.4,7.1,1.4,10.7c0.1,67.9,0.1,135.9,0.1,203.8
			c0,2.5,0,5.1,0.7,7.5c2,7.5,8.9,11.6,17.5,10.8c8-0.8,13.8-6.2,13.9-14c0.3-19.4,0.2-38.8,0.3-58.3c0-18.5,0-37,0-55.5
			c0.1-17.2,14.7-32.1,31.5-32.2c17.1-0.1,31.9,14.6,32.1,32c0.1,5.4-0.1,10.7,0.1,16.1c0.3,10.1,6.3,15.7,16.4,15.6
			c7.4-0.1,14.7-0.1,22.1,0c5.8,0,9.6,3.3,9.5,8.1c-0.1,4.6-3.8,7.7-9.4,7.7c-7.2,0-14.5,0-21.7,0c-17.7-0.1-32.1-14.4-32.5-32.1
			c-0.1-6.6,0.1-13.1-0.6-19.6c-0.9-7.9-7.5-12.5-16.6-12.3c-8.1,0.2-14.5,5.2-14.8,12.9c-0.6,12.8-0.5,25.7-0.6,38.5
			c-0.1,25.6,0.1,51.2,0,76.8c-0.1,17.3-15.1,31.9-32.2,31.8c-16.4-0.1-31.4-15.4-31.4-32.4c-0.1-45.7,0-91.4,0-137.1
			c0-23.3,0-46.6,0-69.9c0-12.1-8.8-18.9-20.7-16.1c-7.1,1.6-11.5,7.1-11.5,14.9c-0.1,16.9-0.1,33.8-0.1,50.6
			C163.6,127.5,163.6,153.3,163.6,179.2C163.6,179.2,163.6,179.2,163.6,179.2z"
      />
    </SvgIcon>
  );
}

Icon.propTypes = {
  className: PropTypes.string,
};
