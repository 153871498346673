import React from 'react';
import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_SELF } from 'graphql/queries/getSelf';
import Loading from 'ui/Loading';

import { useAuth0 } from 'context/auth';

const LoginCheck = () => {
  //do an authcheck and then redirect to the flow
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const [getSelf, { data }] = useLazyQuery(GET_SELF);

  useEffect(() => {
    const validateUser = async () => {
      if (!isAuthenticated) {
        try {
          await loginWithRedirect({
            redirect_uri: `${window.location.origin}/login-check`,
          });
        } catch (e) {
          console.log('Login failed');
        }
      } else {
        getSelf();
      }
    };
    validateUser();
  }, [isAuthenticated, loginWithRedirect, getSelf]);

  if (!isAuthenticated) {
    return <Loading />;
  }

  if (data) {
    console.log('self', data);
    window.location.replace(`${window.location.origin}/the-flow`);
  }

  return (
    <div>
      <Loading />;
    </div>
  );
};

export default LoginCheck;
