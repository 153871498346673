import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { MEMBER_STATUS } from 'config/constants';
import Select, { Option } from 'ui/SelectInput';
import {
  Container,
  Title,
  ButtonSubmit,
  WrappeInputRadio,
  WrapperInput,
  WrapperAlert,
  AlertTitle,
  AlertMessage,
  WrapperCheckbox,
  WrapperBottom,
} from 'pages/TheFlow/Steps/Setup/styles';
import useSetup from 'pages/TheFlow/data/useSetup';
import CancelAccountModal from 'pages/TheFlow/Steps/CancelAccountModal';
import Megafone from 'assets/img/setup/megaphone.png';
import PdfFileSource from 'assets/files/terms-conditions.pdf';

const Setup = () => {
  const { MEMBER, PREMEMBER } = MEMBER_STATUS;
  const {
    regionsOptions,
    languageOptions,
    usaRegion,
    enLanguage,
    handleSubmitSetup,
  } = useSetup();

  const { handleSubmit, register, watch, setValue, errors } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [showHeadesUp, setShowsHeadsUp] = useState(false);
  const [inputErrors, setInputErrors] = useState([]);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const { regionId, languageId } = watch(['regionId', 'languageId']);

  const handleOpenCancelModal = () => setShowModal(true);

  const handleCloseCancelModal = () => setShowModal(false);

  const onSubmit = async data => {
    if (data) {
      setIsLoading(true);
      await handleSubmitSetup(data);
      setIsLoading(false);
    }
  };

  const onChange = e => setValue('status', e.target.value);

  const onAccepteTerms = () => setIsTermsAccepted(value => !value);

  useEffect(() => {
    if (errors) {
      const fieldErros = Object.entries(errors).map(([key]) => key);
      setInputErrors(fieldErros);
    }
  }, [errors]);

  useEffect(() => {
    if (
      (regionId &&
        languageId &&
        languageId !== enLanguage &&
        regionId !== usaRegion) ||
      inputErrors.includes('regionId') ||
      inputErrors.includes('languageId')
    ) {
      setShowsHeadsUp(true);
    }

    if (
      regionId &&
      languageId &&
      languageId === enLanguage &&
      regionId === usaRegion
    ) {
      setShowsHeadsUp(false);
    }
  }, [enLanguage, inputErrors, languageId, regionId, usaRegion]);

  return (
    <Container>
      <Title>Are you a Member?</Title>
      <form onSubmit={handleSubmit(onSubmit)}>
        <WrappeInputRadio hasError={inputErrors.includes('oweStatus')}>
          <input
            name="oweStatus"
            id="opt1"
            ref={register({ required: true })}
            type="radio"
            value={PREMEMBER}
            onChange={onChange}
          />
          <label htmlFor="opt1">
            <strong>I DO NOT</strong> have a doTERRA membership.
          </label>
        </WrappeInputRadio>
        <WrappeInputRadio hasError={inputErrors.includes('oweStatus')}>
          <input
            name="oweStatus"
            id="opt2"
            ref={register({ required: true })}
            type="radio"
            value={MEMBER}
            onChange={onChange}
          />
          <label htmlFor="opt2">
            <strong>I DO HAVE</strong> a doTERRA membership
          </label>
        </WrappeInputRadio>

        {/* <WrapperInput> // THIS FEATURE WILL BE BACK IN THE FUTURE
          <label>Region</label>
          <div>
            <Select
              name="regionId"
              ref={register({
                required: true,
                validate: value => value === usaRegion,
              })}
              hasError={inputErrors.includes('regionId')}>
              <Option value={usaRegion}>United States</Option>
              {regionsOptions.map(item => (
                <Option key={item.value} value={item.value}>
                  {item.text}
                </Option>
              ))}
            </Select>
          </div>
        </WrapperInput>
        <WrapperInput>
          <label>Preferred Language</label>
          <div>
            <Select
              name="languageId"
              ref={register({
                required: true,
                validate: value => value === enLanguage,
              })}
              hasError={inputErrors.includes('languageId')}>
              <Option value={enLanguage}>English</Option>
              {languageOptions.map(item => (
                <Option key={item.value} value={item.value}>
                  {item.text}
                </Option>
              ))}
            </Select>
          </div>
        </WrapperInput> */}

        <WrapperCheckbox>
          <input
            name="termsAndConditions"
            type="checkbox"
            value={isTermsAccepted}
            onChange={onAccepteTerms}
          />
          <label>
            I accept the following Terms of Use and Privacy Policy{' '}
            <a href={PdfFileSource} target="_blank" rel="noopener noreferrer">
              here.
            </a>
          </label>
        </WrapperCheckbox>
        <WrapperBottom>
          <ButtonSubmit
            type="submit"
            disabled={isLoading || !isTermsAccepted}
            isDisabled={isLoading || !isTermsAccepted}>
            Confirm
          </ButtonSubmit>
          <div onClick={handleOpenCancelModal}>Cancel</div>
        </WrapperBottom>
      </form>
      {showHeadesUp && (
        <WrapperAlert>
          <AlertTitle>
            Heads Up!{' '}
            <img alt="Logo" width="66px" height="46px" src={Megafone} />
          </AlertTitle>
          <AlertMessage>
            Because of the complexities of automation, the current version of
            Drippl supports US business-builders only. But don’t worry! We are
            refining and optimizing our system to work for an international
            audience soon!
          </AlertMessage>
        </WrapperAlert>
      )}
      <CancelAccountModal
        isOpen={showModal}
        handleClose={handleCloseCancelModal}
      />
    </Container>
  );
};

export default Setup;
