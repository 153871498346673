import React from 'react';

import {
  ContainerSectionPrice,
  WrapperPriceMonth,
  WrapperPriceYear,
} from 'pages/BecomeVIP/SectionPrice/styles';

const SectionPrice = ({ handleOpenMoldal }) => {
  const handleActivateMonth = () => {
    handleOpenMoldal('monthly');
  };

  const handleActivateYear = () => {
    handleOpenMoldal('yearly');
  };

  return (
    <ContainerSectionPrice>
      <WrapperPriceMonth>
        <h2>Month-to-Month</h2>
        <p>
          First 14 Days FREE, then
          <br />
          <span>$14.95</span> Only $4.95 Per Month!
        </p>
        <button onClick={handleActivateMonth}>Activate Now!</button>
      </WrapperPriceMonth>
      <WrapperPriceYear>
        <span>GET 2 MONTHS FREE</span>
        <h2>One Year</h2>
        <p>
          First 14 Days FREE, then
          <br />
          <span>$149.50</span> Only $49.50
        </p>
        <button onClick={handleActivateYear}>Activate Now!</button>
      </WrapperPriceYear>
    </ContainerSectionPrice>
  );
};

export default SectionPrice;
