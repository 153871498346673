const VIP_STATUS = {
  BUILDER: {
    EXPLORE: { itemComplete: true },
    TRY: { itemComplete: true },
    PREPARE: { itemComplete: true },
    AUTOMATE: { itemComplete: true },
  },
};

export const VIP_PAGE = {
  BUILDER: {
    header: {
      title: 'Ready to build fearlessly?',
      subtitle: 'Invite, educate, and enroll with Drippl!',
      text: '',
      videoUrl: 'https://vimeo.com/428336082',
      stepStatus: VIP_STATUS.BUILDER,
    },
    body: {
      title: '',
      text:
        'Below you will find your activated Personal Referral Link that you can start sharing immediately. Your contacts will have the chance to enter Drippl and begin their journey of discovery. When they choose to purchase products, they will go to your personal enrollment page and join your team! \n To help you stay on track, you’ll also find your unique dashboard where you can set goals, view stats, and more.',
    },
    highlight:
      'Let the journey to better health, more wealth, \n and freedom begin!',
  },
};
