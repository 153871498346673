import styled, { css } from 'styled-components';
import ButtonDefault from 'ui/Button/Default';
import AnchorLink from 'react-anchor-link-smooth-scroll';

export const ContainerVideoView = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: ${theme.colors.white};
    border-radius: 14px;
    border: 0px solid #000;
    @media (min-width: 600px) {
      width: ${theme.container.max};
      margin-bottom: 33px;
      padding: 28px 43px;
    }
  `};
`;

export const SectionTitle = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 0 10px;
    @media (min-width: 600px) {
      padding: 0;
      flex-direction: row;
      justify-content: space-between;
    }
  `};
`;

export const Title = styled.h1`
  ${() => css`
    font-size: 22px;
    line-height: 31px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 10px;
    padding: 0 10px;
    @media (min-width: 600px) {
      font-size: 32px;
      line-height: 41px;
      margin-bottom: 0px;
    }
  `};
`;

export const SectionVideo = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    @media (min-width: 600px) {
      flex-direction: row;
      justify-content: flex-start;
    }
  `};
`;

export const VideoList = styled.div`
  ${() => css`
    padding-bottom: 5px;
    order: 2;
    height: 280px;
    overflow-y: auto;
    @media (min-width: 600px) {
      height: 600px;
      width: 35%;
      order: 1;
    }
  `};
`;

export const VideoCategory = styled.div`
  ${() => css`
    display: flex;
    height: auto;
    padding: 13px 17px;
    flex-direction: row;
    justify-content: flex-start;
    background-color: #4b4b4b;
    color: #fff;
    @media (min-width: 600px) {
    }
  `};
`;

export const VideoItem = styled.div`
  ${({ isSelected }) => css`
    display: flex;
    padding: 5px;
    background-color: ${isSelected ? '#c1e9e9' : '#e9e9e9'};
    transition: padding 200ms cubic-bezier(0.25, 0.1, 0.608, 2.354),
      background-color 300ms ease;
    height: 80px;
    margin-top: 5px;
    cursor: pointer;
    :hover {
      padding-left: 6px;
      background-color: #c1e9e9;
    }
    @media (min-width: 600px) {
    }
  `};
`;

export const VideoItemThumb = styled.div`
  ${() => css`
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: ${({ src }) => `url(${src})`};
    border: none;
    width: 86px;
    height: 70px;
    margin-right: 10px;
    @media (min-width: 600px) {
    }
  `};
`;

export const VideoItemDescription = styled.div`
  ${() => css`
    padding-top: 5px;
    height: 60px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-width: 220px;
    @media (min-width: 600px) {
    }
  `};
`;

export const VideoDisplay = styled.div`
  ${() => css`
    background-color: #f0f0f0;
    order: 1;
    margin-bottom: 10px;
    position: -webkit-sticky;
    position: sticky;
    top: 70px;
    @media (min-width: 600px) {
      position: inherit;
      width: 73%;
      height: 600px;
      order: 2;
      margin-bottom: 0px;
      margin-left: 5px;
    }
  `};
`;

export const ButtonTheFlow = styled(ButtonDefault)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  border: 1px solid #d4d4d4;
  border-radius: 8px;
  font-weight: 500;
  width: 150px;
  font-size: 14px;
  padding: 5px;
  position: relative;
  @media (min-width: 600px) {
    padding: 8px;
    letter-spacing: 1px;
    width: 130px;
  }
`;

export const WrapperVideo = styled.div`
  ${() => css`
    padding: 0;
    @media (min-width: 600px) {
      padding: 20px;
    }
  `};
`;

export const WrapperVideoDescription = styled.div`
  ${() => css`
    background-color: white;
    padding: 17px 25px 25px;
    p {
      color: #1f1f1f;
      line-height: 18px;
      font-weight: 300;
      font-size: 14px;
    }
    @media (min-width: 600px) {
    }
  `};
`;

export const WrapperVideoTitle = styled.div`
  ${() => css`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
    h3 {
      color: #1f1f1f;
      font-weight: 600;
      max-width: 240px;
      font-size: 18px;
      line-height: 17px;
    }
    span {
      text-transform: uppercase;
      color: #a7a7a7;
      font-size: 12px;
      line-height: 22px;
      font-weight: 500;
    }
    @media (min-width: 600px) {
      h3 {
        font-size: 19px;
        line-height: 18px;
        max-width: 90%;
      }
    }
  `};
`;

export const Description = styled.div`
  ${({ isVisible }) => css`
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: ${isVisible ? 0 : 3};
    -webkit-box-orient: vertical;
    position: relative;
    margin-bottom: 10px;

    @media (min-width: 600px) {
    }
  `};
`;

export const WrapperDescription = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    span {
      align-self: flex-end;
      font-size: 12px;
      cursor: pointer;
    }
    @media (min-width: 600px) {
      span {
        display: none;
      }
    }
  `};
`;

export const WrapperPlayer = styled.div`
  ${() => css`
    height: 100%;
    @media (min-width: 600px) {
      height: 397px;
    }
  `};
`;

export const SectionAttachment = styled.div`
  ${() => css`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    h3 {
      margin-bottom: 22px;
      font-size: 20px;
      font-weight: 600;
      padding-left: 10px;
    }
    @media (min-width: 600px) {
      h3 {
        padding-left: 0;
      }
    }
  `};
`;

export const WrapperAttachment = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    background-color: #f0f0f0;

    padding: 30px 0;

    @media (min-width: 600px) {
      padding: 30px 20px;
      min-height: 220px;
      flex-direction: row;
    }
  `};
`;

export const Attachment = styled.a`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    position: relative;
    width: 93%;
    margin: 12px 0;
    padding: 10px 13px;
    align-items: center;
    border: 1px solid #f5f5f5;
    border-radius: 10px;
    background-color: ${theme.colors.white};
    transform: translate(0px, 0px);
    transition: border-color 200ms ease,
      transform 200ms cubic-bezier(0.25, 0.1, 0.632, 2.096);
    text-decoration: none;
    :hover {
      outline: 0;
      border-color: 1px solid ${theme.colors.primary};
      background-color: #e7ffff;
      transform: translate(0px, -3px);
    }
    span {
      font-size: 14px;
      color: #1f1f1f;
      line-height: 18px;
      font-weight: 300;
      text-align: center;
      text-decoration: none;
      margin-left: 5px;
    }
    @media (min-width: 600px) {
      width: 20%;
      flex-direction: column;
      margin: 0 16px;
      padding: 22px;
      align-self: stretch;
      margin-bottom: 30px;
      span {
        margin-top: 12px;
        font-size: 16px;
      }
    }
  `};
`;

export const File = styled.div`
  ${() => css`
    width: 20px;
    height: 20px;
    margin-left: 5px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: ${({ src }) => `url(${src})`};
    @media (min-width: 600px) {
      width: 50px;
      height: 50px;
      margin-left: 0;
    }
  `};
`;

export const WrapperIcon = styled.div`
  ${({ theme }) => css`
    position: absolute;
    left: auto;
    top: 16%;
    right: 0%;
    bottom: auto;
    display: flex;
    width: 30px;
    height: 30px;
    margin-top: -6px;
    margin-right: -6px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background-color: ${theme.colors.primary};
    color: #fff;
    font-size: 16px;
    @media (min-width: 600px) {
      top: 0%;
      right: 0%;
    }
  `};
`;

export const MobileSupportFiles = styled.div`
  ${() => css`
    margin-top: 20px;
    @media (min-width: 600px) {
      display: none;
    }
  `};
`;

export const SectionAttachmentMobile = styled(AnchorLink)`
  ${() => css`
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    position: relative;
    :visited {
      color: black;
    }
  `};
`;
