import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'ui/Modal';

import {
  Container,
  SectionHeader,
  SectionBody,
  SectionFooter,
  ButtonCancel,
  ButtonSave,
} from 'pages/TheFlow/Steps/PrepareToShareModal/styles';
import useTheFlow from 'pages/TheFlow/data/useTheFlow';

import { POSITION_STATUS, MEMBER_STATUS } from 'config/constants';

const LOGIN = 'https://login.doterra.com/us/en-us/sign-in';

const PrepareToShareModal = ({ isOpen, handleClose }) => {
  const { PENDING_BUILDER } = POSITION_STATUS;
  const { MEMBER } = MEMBER_STATUS;
  const { handleUpdateMemberStatuses } = useTheFlow();

  const onSubmit = async () => {
    await handleUpdateMemberStatuses({
      positionStatus: PENDING_BUILDER,
      oweStatus: MEMBER,
    });
    handleClose();
  };

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} hideButtons>
      <Container>
        <SectionHeader>
          <h2>Free Upgrade to Wellness Advocate</h2>
        </SectionHeader>
        <SectionBody>
          <p>
            The button below will take you to the dōTERRA site where you can
            upgrade your Wholesale Account to Wellness Advocate. When you're
            done, just come back to Drippl for next steps!
            <br />
            <br />
            If you need any assistance while upgrading, you can call dōTERRA
            support in your area for assistance. 1 (800) 411-8151
          </p>
        </SectionBody>
        <SectionFooter>
          <ButtonCancel onClick={handleClose}>Cancel</ButtonCancel>
          <ButtonSave
            href={LOGIN}
            onClick={onSubmit}
            target="_blank"
            rel="noopener noreferrer">
            Upgrade Now
          </ButtonSave>
        </SectionFooter>
      </Container>
    </Modal>
  );
};

export default PrepareToShareModal;

PrepareToShareModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
};
