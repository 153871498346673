import React from 'react';
import LogoDrippl from 'assets/img/logos/drippl-white-bg.png';
import {
  Container,
  Nav,
  Link,
  RighNav,
  EnterButton,
} from 'ui/layouts/Home/Navbar/styles';

const NavBar = () => (
  <Container>
    <Nav>
      <Link to="/">
        <img alt="Logo" height="48px" src={LogoDrippl} width="100px" />
      </Link>
      <RighNav>
        <EnterButton to="/login-check">Enter Drippl</EnterButton>
      </RighNav>
    </Nav>
  </Container>
);

export default NavBar;
