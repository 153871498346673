import React, { Fragment, useEffect } from 'react';
import { PageContent } from 'ui/layouts';
import Steps from 'pages/TheFlow/Steps';
import Presentation from 'pages/TheFlow/Presentation';
import UserUtils from 'pages/TheFlow/UserUtils';
import useTheFlow from 'pages/TheFlow/data/useTheFlow';
import StatusProgress from 'ui/StatusProgress';
import WelcomeHeader from 'ui/WelcomeHeader';
import ChooseLeader from 'ui/ChooseLeaderModal';

const TheFlow = () => {
  const {
    handleUpdatePositionStatus,
    handleUpdateMemberStatus,
    data,
    statusBar,
    pageContent,
    loading,
    error,
    videosInfo,
    referralId,
  } = useTheFlow();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [data]);

  return (
    <Fragment>
      {/* <TestCookie /> */}
      <WelcomeHeader />
      <StatusProgress statusBar={statusBar} />
      <PageContent>
        <Presentation pageContent={pageContent} />
        <Steps
          data={data}
          loading={loading}
          error={error}
          videosInfo={videosInfo}
          handleUpdatePositionStatus={handleUpdatePositionStatus}
          handleUpdateMemberStatus={handleUpdateMemberStatus}
          referralId={referralId}
        />
      </PageContent>
      <UserUtils data={data} />
      <ChooseLeader />
    </Fragment>
  );
};

export default TheFlow;
